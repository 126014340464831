<template>
  <div class="integrated-count-heading"><slot></slot></div>
</template>

<script>
export default {
  name: 'integrated-count-heading',
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.integrated-count-heading {
  background: $orange-01;
  color: $white-01;
  padding: 10px 14px;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
}
</style>
