<template>
  <fieldset class="form-checkbox">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="form-checkbox-label"
    >
      <input
        type="checkbox"
        v-model="checked"
        :name="name"
        :value="option.value"
        class="checkbox"
      />{{ option.label }}
    </label>
  </fieldset>
</template>

<script>
export default {
  name: 'form-checkbox',
  props: {
    modelValue: { type: Array, require: true },
    options: { type: Array, require: true },
    name: { type: String, require: true },
  },
  emits: ['update:modelValue'],
  computed: {
    checked: {
      get: function () {
        return this.modelValue
      },
      set: function (newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  > .checkbox {
    margin-right: 10px;
  }
  + .form-checkbox-label {
    margin-top: 10px;
  }
}
</style>
