<template>
  <div class="right-count-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'right-count-container'
}
</script>

<style lang="scss" scoped>
.right-count-container {
  display: flex;
  justify-content: center;

  :deep(.right-count-box) {
    &:nth-child(n+2) {
      margin-left: 5px;
    }
  }
}
</style>
