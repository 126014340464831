<template>
  <p class="error-box"><slot></slot></p>
</template>

<script>

export default {
  name: 'error-box'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.error-box {
  padding: 10px;
  font-size: 12px;
  line-height: 1.4;
  color: $red-text;
  background-color: $red-bg;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
