<template>
  <transition name="modal">
    <div class="modal" :class="{['is-'+variant]:variant}" v-show="isShowModal">
      <div class="modal-container">
        <button-close v-if="isShowTopButtonClose" class="-top" @click="hideModal"/>
        <div class="modal-content"><slot></slot></div>
        <button-close v-if="isShowBottomButtonClose" class="-bottom" @click="hideModal"/>
      </div>
    </div>
  </transition>
</template>

<script>
  import ButtonClose from '@/components/button/ButtonClose'

  export default {
    name: 'Modal',
    components: {ButtonClose},
    props: {
      variant: { type: String, require: false},
      isShowTopButtonClose: { type: Boolean, default: false },
      isShowBottomButtonClose: { type: Boolean, default: true }
    },
    data() {
      return {
        isShowModal: false
      }
    },
    methods: {
      openModal() {
        this.isShowModal = true
      },
      hideModal() {
        this.isShowModal = false
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black-01, 0.6);
    overflow-y: scroll;
    padding: 0 10%;
    z-index: 999;

    &.is-small {
      .modal-container {
        @include mq-up(md) {
          max-width: 540px;
        }
      }
    }

    .modal-container {
      position: relative;
      background-color: $white-01;
      margin: 80px auto;
      width: 100%;
      padding: 10px;
      @include mq-up(md) {
        max-width: 1000px;
      }

      .button-close {
        &.-top {
          margin: 0 0 0 auto;
        }

        &.-bottom {
          margin: 0 auto;
        }
      }

      .modal-content {
        padding: 10px 0 30px;
      }
    }
  }

  .modal-enter-active, .modal-leave-active {
    transition: opacity .2s;
  }

  .modal-enter, .modal-leave-to {
    opacity: 0;
  }
</style>
