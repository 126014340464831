<template>
  <ul class="integrated-device-box">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'integrated-device-box'
}
</script>

<style lang="scss" scoped>
.integrated-device-box {
  padding: 20px;
}
</style>
