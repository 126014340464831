<template>
  <ul class="integrated-count-box"><slot></slot></ul>
</template>

<script>
export default {
  name: 'integrated-count-box'
}
</script>

<style lang="scss" scoped>
.integrated-count-box{
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
