<template>
  <div class="common-support">
    <p class="text -left">ご不明な点はこちらに</p>
		<p class="header">修理代金サポートセンター</p>
		<p class="tel"><a href="tel:0120914554">0120-914-554</a></p>
		<p class="text">（通話料無料）</p>
		<p class="text">受付時間 <span class="large">9:00～20:00</span>（年中無休）</p>
  </div>
</template>

<script>

export default {
  name: 'common-support',
  props: {
    isHoken: { type: Boolean, require: false, 'default':false }
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.common-support {
  max-width: 500px;
  border: 1px solid $gray-line;
  padding: 20px;
  margin: 0 auto;
  text-align: center;

  .header {
    font-size: 18px;
    line-height: 15px;
    margin-top: 20px;
  }

  .tel {
    margin-top: 15px;

    a {
      color: $orange-01;
      font-weight: bold;
      font-size: 20px;
      line-height: 1.4;
      text-decoration: none;

      @include mq-up(sm) {
        pointer-events: none;
      }
    }

    + .text {
      margin-top: 5px;
    }
  }

  .text {
    font-size: 14px;
    line-height: 140%;

    &.-left {
      display: inline-block;
      text-align: left;
    }

    + .text {
      margin-top: 10px;
    }

    .large {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
