<template>
  <div class="form-date" :class="classes">
    <DatePicker
      :value="modelValue"
      :placeholder="placeholder"
      :name="name"
      valueType="YYYY/MM/DD"
      :format="format"
      :disabled="disabled"
      :input-class="['datepicker', {'is-error':error}]"
      :language="jaLocale"
      :lang="langObject"
      :clear-button="true"
      :disabled-date="maxDate"
      @update:value="updateDateInput"
    >
      <template #icon-calendar>
        <img v-if="showCalenderIcon" src="@/assets/images/icon-calendar.svg" alt="" class="icon">
      </template>
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import jaLocale from 'vue-datepicker-next/locale/ja';
import 'vue-datepicker-next/index.css';

export default {
  name: 'FormDate',
  components: {
    DatePicker
  },
  props: {
    modelValue: { type: [Date, String], required: true, default: '' },
    size: { type: String, required: false },
    name: { type: String, required: true },
    placeholder: { type: String, required: false, default: 'YYYY/MM/DD' },
    disabled: { type: Boolean, required: false, default: false },
    error: { type: Boolean, required: false, default: false },
    showCalenderIcon: { type: Boolean, required: false, default: true },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      format: 'YYYY/MM/DD',
      formatSmall: 'YYYY/M/D',
      jaLocale: jaLocale,
      langObject: {
        yearFormat: 'YYYY年',
      },
    };
  },
  computed: {
    classes() {
      let clazz = []
      if (this.size) {
        clazz.push('is-' + this.size)
      }
      return clazz
    }
  },
  methods: {
    updateDateInput(newDate) {
      this.$emit("update:modelValue", newDate)
    },
    maxDate(date) {
      return date > new Date(9999, 11, 31, 23, 59, 59);
    },
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";

.form-date {
  display: inline-block;
  font-size: 14px;
  &.is-small {
    width: 120px;
  }
  &.is-auto {
    width: 100%;
  }
}
.mx-datepicker {
  width: 100%;
}
</style>

<style lang="scss">
@import "@/assets/stylesheets/variables";

.mx-datepicker {
  width: 100%;
  &.is-error {
    background-color: $red-lighter;
    border: 1px solid $red-base;
  }
}
.datepicker {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 9.5px 30px;
  padding-left: 10px;
  line-height: 1.4;
  border: 1px solid $gray-line;
  border-radius: 3px;
  &::placeholder {
    color: $gray-text;
  }
  &.is-error {
    background-color: $red-lighter;
  }
}
.mx-input::placeholder {
  color: $gray-text;
}
.mx-icon-calendar, .mx-icon-clear  {
  color: $gray-line;
}
</style>
