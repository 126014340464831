<template>
  <div class="form-major-bank">
    <button v-for="(bank, index) in majorBanks" :key="index" @click="$emit('select', bank)">{{bank.bankName}}</button>
  </div>
</template>

<script>
  export default {
    name: 'form-major-bank',
    data() {
      return {
        majorBanks: [
          {bankName: '三菱UFJ銀行', bankCode: '0005'},
          {bankName: '三井住友銀行', bankCode: '0009'},
          {bankName: 'みずほ銀行', bankCode: '0001'},
          {bankName: 'りそな銀行', bankCode: '0010'},
          {bankName: 'じぶん銀行', bankCode: '0039'}
        ]
      }
    },
    emits: ['select']
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .form-major-bank {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px -10px 0;

    button {
      background-color: $white-01;
      border: 1px solid $gray-line;
      border-radius: 6px;
      padding: 9px 8px;
      font-size: 18px;
      color: $link-text-02;
      margin: 0 10px 10px 0;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }
    }
  }
</style>
