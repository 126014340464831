<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>端末情報事前登録一覧</SectionHeading>
    <ContentsContainer>
      <ErrorBox v-if="isError">申請内容に不備がございますので事前登録は完了していません。不備事由をご確認ください。</ErrorBox>

      <ItemContainer>
        <template v-if="isEmpty">
          <ContentsText>保険対象端末が登録されていません。<br>修理代金サポート(保険)の利用には端末情報事前登録が必要です。</ContentsText>
          <ButtonToggle toggle="open">
            <template v-slot:header>端末情報事前登録</template>
            <template v-slot:body>
              <ButtonToggleBody>
                <ButtonContainer>
                  <ButtonMain variant="primary" href="https://pass.auone.jp/anshin/service/hoken/">事前登録ページへ</ButtonMain>
                </ButtonContainer>
              </ButtonToggleBody>
            </template>
          </ButtonToggle>
        </template>

        <template v-else>
          <ButtonToggle v-if="hasClaimUnderReview" toggle="open">
            <template v-slot:header>端末情報事前登録手続き中</template>
            <template v-slot:body>
              <ButtonToggleBody>
                <ItemContainer>
                  <HeadingLabel>事前申請番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.advanceEntryNumber}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>申請日</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.appDate}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>保険対象端末</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.deviceName}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>シリアル番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.serialNo ? claimUnderReview.serialNo : '確認中'}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>申請状況</HeadingLabel>
                  <CheckText :variant="checkType[claimUnderReview.statusCode]">{{claimUnderReview.status}}</CheckText>
                  <SmallText v-if="isError && claimUnderReview.reason">{{claimUnderReview.reason}}</SmallText>
                </ItemContainer>
                <ButtonContainer v-if="isError">
                  <ButtonMain @click="goEntry(claimUnderReview.advanceEntryNumber)" :disabled="!canPushClaimButton">不備を修正する</ButtonMain>
                  <ButtonMain variant="danger" @click="claimCancel(claimUnderReview.advanceEntryNumber)" :disabled="!canPushClaimButton">申請を取り消す</ButtonMain>
                </ButtonContainer>
              </ButtonToggleBody>
            </template>
          </ButtonToggle>

          <ButtonToggle v-if="hasSupportedDevice" toggle="open">
            <template v-slot:header>現在、保険対象になっている端末</template>
            <template v-slot:body>
              <ButtonToggleBody>
                <ItemContainer>
                  <HeadingLabel>事前申請番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{supportedDevice.advanceEntryNumber}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>申請日</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{supportedDevice.appDate}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>保険対象端末</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{supportedDevice.deviceName}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>シリアル番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{supportedDevice.serialNo}}</ContentsText>
                </ItemContainer>
                <ItemContainer>
                  <HeadingLabel>利用可能回数</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{supportedDevice.numberOfInsurance}}回</ContentsText>
                </ItemContainer>
                <ButtonContainer>
                  <ButtonMain variant="primary" @click="goInsurance(supportedDevice.advanceEntryNumber)" :disabled="supportedDevice.numberOfInsurance === 0 || !canPushClaimButton">保険金を請求する</ButtonMain>
                </ButtonContainer>
              </ButtonToggleBody>
            </template>
          </ButtonToggle>

          <ButtonToggle v-if="hasPastSupportedDeviceList" toggle="open">
            <template v-slot:header>保険金請求ができる端末一覧</template>
            <template v-slot:body>
              <template v-for="(pastSupportedDevice, index) in pastSupportedDeviceList" :key="index">
                <ButtonToggleBody>
                  <ItemContainer>
                    <HeadingLabel>事前申請番号</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{pastSupportedDevice.advanceEntryNumber}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>申請日</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{pastSupportedDevice.appDate}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>保険対象端末</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{pastSupportedDevice.deviceName}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>シリアル番号</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{pastSupportedDevice.serialNo}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>利用可能回数</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{pastSupportedDevice.numberOfInsurance}}回</ContentsText>
                  </ItemContainer>
                  <ButtonContainer>
                    <ButtonMain variant="primary" @click="goInsurance(pastSupportedDevice.advanceEntryNumber)" :disabled="pastSupportedDevice.numberOfInsurance === 0 || !canPushClaimButton">保険金を請求する</ButtonMain>
                  </ButtonContainer>
                </ButtonToggleBody>
              </template>
            </template>
          </ButtonToggle>

          <ButtonToggle toggle="open">
            <template v-slot:header>端末情報事前登録</template>
            <template v-slot:body>
              <ButtonToggleBody>
                <ButtonContainer>
                  <ButtonMain variant="primary" href="https://pass.auone.jp/anshin/service/hoken/" :disabled="!canPushClaimButton">申請する</ButtonMain>
                </ButtonContainer>
              </ButtonToggleBody>
            </template>
          </ButtonToggle>

          <ButtonToggle v-if="hasClaimHistoryList" toggle="open">
            <template v-slot:header>申請履歴情報</template>
            <template v-slot:body>
              <template v-for="(claimHistory, index) in claimHistoryList" :key="index">
                <ButtonToggleBody>
                  <ItemContainer>
                    <HeadingLabel>事前申請番号</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{claimHistory.advanceEntryNumber}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>申請日</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{claimHistory.appDate}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>保険対象端末</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{claimHistory.deviceName}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>シリアル番号</HeadingLabel>
                    <ContentsText variant="bold" :breakword="true">{{claimHistory.serialNo ? claimHistory.serialNo : '-'}}</ContentsText>
                  </ItemContainer>
                  <ItemContainer>
                    <HeadingLabel>申請結果</HeadingLabel>
                    <CheckText :variant="checkType[claimHistory.statusCode]">{{claimHistory.status}}</CheckText>
                    <SmallText v-if="(claimHistory.statusCode === 3 || claimHistory.statusCode === 5) && claimHistory.reason">{{claimHistory.reason}}</SmallText>
                  </ItemContainer>
                </ButtonToggleBody>
              </template>
            </template>
          </ButtonToggle>
        </template>
      </ItemContainer>

      <ButtonContainer>
        <ButtonMain :to="{name: 'rep010', params: {id: this.$route.query.p}}">戻る</ButtonMain>
      </ButtonContainer>

      <CommonSupport />
      <ButtonContainer>
        <ButtonMain href="https://pass.auone.jp/anshin/#anshin-support">auスマートパス&emsp;マイページ</ButtonMain>
      </ButtonContainer>
    </ContentsContainer>

    <CommonLoading v-if="isLoading"/>
    <CommonFooter/>
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsContainer from '@/components/container/ContentsContainer'
import ItemContainer from '@/components/container/ItemContainer'
import HeadingLabel from '@/components/heading/HeadingLabel'
import ContentsText from '@/components/text/ContentsText'
import SmallText from '@/components/text/SmallText'
import CheckText from '@/components/text/CheckText'
import ButtonToggle from '@/components/button/ButtonToggle'
import ButtonToggleBody from '@/components/button/ButtonToggleBody'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'
import ErrorBox from '@/components/error/ErrorBox'

import {postRefEntryListApi, postClaimCancelApi} from '@/utils/ApiHelper'
import {$_resultError, $_isError} from '@/utils/Error'

export default {
  name: "rep030",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    SectionHeading,
    ContentsContainer,
    ItemContainer,
    HeadingLabel,
    ContentsText,
    SmallText,
    CheckText,
    ButtonToggle,
    ButtonToggleBody,
    ButtonContainer,
    ButtonMain,
    ErrorBox
  },
  data () {
    return {
      isLoading: false,
      supportedDevice: {},
      claimUnderReview: {},
      pastSupportedDeviceList: [],
      claimHistoryList: [],
      canPushClaimButton: false,
      checkType: {
        0: 'progress',
        1: 'success',
        2: 'error',
        3: 'error',
        4: 'error',
        5: 'error'
      },
      resultError: []
    }
  },
  computed: {
    isError () {
      // 「不備」の場合
      return this.claimUnderReview.statusCode === 2
    },
    isEmpty () {
      return !this.hasClaimUnderReview && !this.hasSupportedDevice && !this.hasPastSupportedDeviceList && !this.hasClaimHistoryList
    },
    hasSupportedDevice() {
      return Object.keys(this.supportedDevice).length > 0
    },
    hasClaimUnderReview() {
      return Object.keys(this.claimUnderReview).length > 0
    },
    hasPastSupportedDeviceList() {
      return this.pastSupportedDeviceList.length > 0
    },
    hasClaimHistoryList() {
      return this.claimHistoryList.length > 0
    }
  },
  mounted () {
    this.refEntryList()
  },
  methods: {
    goInsurance(advanceEntryNumber) {
      if (this.isLoading) return false
      this.isLoading = true
      let claimInit = {
        advanceEntryNumber: advanceEntryNumber,
        isUpdate: false,
        pageId: 'rep130',
        isAnotherTel: false
      }
      this.$store.dispatch('setClaimInit', claimInit)
      // 「保険金を請求する」medibaフラグを保険(3)に変更する
      this.$router.push({ name: 'rep130', query: {'p': '3'} })
      this.scrollTop()
      this.isLoading = false
    },
    goEntry(advanceEntryNumber) {
      if (this.isLoading) return false
      this.isLoading = true
      let claimInit = {
        advanceEntryNumber: advanceEntryNumber,
        isUpdate: true,
        pageId: 'rep140',
        isAnotherTel: false
      }
      this.$store.dispatch('setClaimInit', claimInit)
      this.$router.push({ name: 'rep140', query: {'p': this.$route.query.p} })
      this.scrollTop()
      this.isLoading = false
    },
    refEntryList() {
      this.isLoading = true;

      postRefEntryListApi()
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.claimUnderReview = result.claimUnderReview
        this.supportedDevice = result.supportedDevice
        this.pastSupportedDeviceList = result.pastSupportedDeviceList
        this.claimHistoryList = result.claimHistoryList
        this.canPushClaimButton = result.canPushClaimButton || false
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
      })
    },
    claimCancel(appNumber) {
      if (this.isLoading) return false
      if (!confirm('取り消しします。本当に宜しいですか？')) {
        return
      }
      this.isLoading = true;
      postClaimCancelApi(appNumber, true)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.refEntryList()
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
      })
    },
    scrollTop() {
      this.$scrollTo('body', 1)
    },
    goCarrier() {
      // 030で申請がない場合、au/UQ/その他でリンク先仕分けるロジックとして残している
      let carrierType = this.$store.getters.getCarrierType
      if (carrierType === 1) {
        window.location = 'https://www.au.com/'
      } else if (carrierType === 2) {
        window.location = 'https://www.uqwimax.jp/'
      } else if (carrierType === 3) {
        window.location = 'https://www.google.com/'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
