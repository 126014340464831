<template>
  <div class="integrated-device-heading">
    <img src="@/assets/images/icon_tel.svg" alt="">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'integrated-device-heading',
};
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/variables";
.integrated-device-heading {
  background: $link-text-02;
  color: $white-01;
  padding: 10px 14px 10px 33px;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  position: relative;
  img {
    display: block;
    width: 11px;
    position: absolute;
    top: 10px;
    left: 14px;
  }
}
.gray .integrated-device-heading{
  background: $gray-text-02;
}
</style>
