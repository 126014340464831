<template>
  <component
		:is="to ? 'router-link' : 'a'"
		class="button-main"
		:class="{
			['is-' + variant]: variant,
			'is-disabled':disabled
		}"
		:href="href"
		:to="to"
		@click="click"
		:target="target"
  ><slot></slot>
  <img src="@/assets/images/icon_arrow_white_right.png" class="arrow" v-if="variant === 'next'">
  </component>
</template>

<script>
export default {
  name: 'button-main',
  props: {
    to: { type: Object, default: null },
    href: { type: String, default: '' },
    variant: { type: String, default: '' },
    target: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  emits: ['click'],
  methods: {
    click: function (e) {
      if (!this.href) {
        e.preventDefault()
      }
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.button-main {
  position: relative;
  display: block;
  box-sizing: border-box;
	border-radius: 10px;
	text-align: center;
	text-decoration: none;
	transition: .2s ease;
  height: 54px;
  font-size: 15px;
  line-height: 52px;
	color: $black-01;
	border: 1px solid $gray-line;
  background-image: $gradation-gray;
  cursor: pointer;
	&:hover{
		background-image: $gradation-gray-02;
  }
  &.is-disabled {
    color: $gray-02;
    cursor: not-allowed;
  }
  @include mq-up(sm) {
    max-width: 320px;
  }
  &.is-primary,
  &.is-next {
    color: $white-01;
    line-height: 54px;
    border: none;
    background-image: $gradation-green;
    &:hover{
      background-image: $gradation-green-02;
    }
    &.is-disabled {
      background-image: $gradation-green-03;
    }
  }
  &.is-danger {
    color: $red-text;
    &.is-disabled {
      color: $gray-02;
    }
  }
  .arrow {
    position: absolute;
    top: 22px;
    right: 17px;
    width: auto;
    height: 10px;
  }
}
</style>
