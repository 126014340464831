<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>電話番号選択</SectionHeading>
    <ContentsContainer>
      <ErrorBox v-if="isError">
        <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
      </ErrorBox>

      <ItemContainer>
        <HeadingLabel>申請する端末の電話番号を選択してください。</HeadingLabel>
        <FormSelect
            v-model="tel"
            name="tel"
            :disabled="isAnotherTel.length > 0"
            :options="telOption"
            :error="'tel' in errorMessage"
        ></FormSelect>
        <FormCheckbox
            v-model="isAnotherTel"
            name="isAnotherTel"
            :options="anotherTelOption"
        ></FormCheckbox>
        <ErrorMessage v-if="'tel' in errorMessage">{{errorMessage.tel}}</ErrorMessage>
        <ContentsText class="mt10">
          <SmallText variant="non-asterisk">
            ※修理代金サポートでは、申請を希望される電話番号と紐づいたauIDでログインする必要がございます。<br>
            ※ご契約もしくは機種変更、本体交換当日の場合には表示される情報が最新でないことがあるため、翌日以降にご利用ください。<br>
            <br>
            申請を希望される電話番号が一覧に表示されない場合はauIDをご確認の上、再度ログインをお願い致します。<br>
            再度ログインを頂いても、電話番号が一覧に表示されない場合は修理代金サポートセンターへお問い合わせください。
          </SmallText>
        </ContentsText>
      </ItemContainer>
      <ButtonContainer>
        <ButtonMain variant="next" @click="next">次へ進む</ButtonMain>
      </ButtonContainer>
      <ItemContainer v-if="hasPassedEntryInfo">
        <HeadingLabel>申請する電話番号がみつかりませんか？</HeadingLabel>
        <ButtonBox :to="{name: 'rep030', query: {'p': this.$route.query.p}}">
          <template v-slot:header>事前申請した端末で申請する</template>
          <template v-slot:text>既に事前申請済みの端末を修理された場合はこちらから、保険金を請求する事が出来ます。</template>
        </ButtonBox>
      </ItemContainer>
      <ButtonContainer>
        <ButtonMain :to="{name: 'rep010', params: {id: this.$route.query.p}}">戻る</ButtonMain>
      </ButtonContainer>
      <CommonSupport/>
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
  import CommonHeader from '@/components/common/CommonHeader'
  import CommonFooter from '@/components/common/CommonFooter'
  import CommonSupport from '@/components/common/CommonSupport'
  import CommonLoading from '@/components/common/CommonLoading'
  import MainContainer from '@/components/container/MainContainer'
  import SectionHeading from '@/components/heading/SectionHeading'
  import ContentsContainer from '@/components/container/ContentsContainer'
  import ItemContainer from '@/components/container/ItemContainer'
  import HeadingLabel from '@/components/heading/HeadingLabel'
  import ContentsText from '@/components/text/ContentsText'
  import SmallText from '@/components/text/SmallText'
  import FormSelect from '@/components/select/FormSelect'
  import FormCheckbox from '@/components/select/FormCheckbox'
  import ErrorBox from '@/components/error/ErrorBox'
  import ErrorMessage from '@/components/error/ErrorMessage'
  import ButtonContainer from '@/components/button/ButtonContainer'
  import ButtonMain from '@/components/button/ButtonMain'
  import ButtonBox from '@/components/button/ButtonBox'

  import {postClaimTelListApi, postClaimPatternApi, postPassedEntryInfoApi} from '@/utils/ApiHelper'
  import {$_resultError, $_validationErrors, $_errorMessages, $_isError} from '@/utils/Error'

  export default {
    name: "rep022",
    components: {
      CommonHeader,
      CommonFooter,
      CommonSupport,
      CommonLoading,
      MainContainer,
      SectionHeading,
      ContentsContainer,
      ItemContainer,
      HeadingLabel,
      ContentsText,
      SmallText,
      FormSelect,
      FormCheckbox,
      ErrorBox,
      ErrorMessage,
      ButtonContainer,
      ButtonMain,
      ButtonBox
    },
    data () {
      return {
        form: {
          tel: "",
          isAnotherTel: false,
          referrerInfo: Number(this.$route.query.p)
        },
        tel: "",
        isAnotherTel: [],
        isLoading: false,
        telList: [],
        hasPassedEntryInfo: false,
        resultError: []
      }
    },
    computed: {
      telOption () {
        let options = [{label: '選択', value: '', disabled: true}]
        for (let tel of this.telList) {
          options.push({label: tel, value: tel, disabled: false})
        }
        return options
      },
      anotherTelOption () {
        return [{label: 'povo2.0の端末で申請される方', value: 'another'}]
      },
      errors() {
        return $_validationErrors(this.resultError)
      },
      isError () {
        return this.errors.length > 0
      },
      errorMessage () {
        let errorMessage = {}
        for (const error of this.errors) {
          if (error.errorItem) {
            errorMessage[error.errorItem] = error.errorMessage
          }
        }
        return errorMessage
      },
      errorMessages () {
        return $_errorMessages(this.resultError)
      }
    },
    methods: {
      next () {
        if (this.isLoading) return false
        this.isLoading = true
        this.resultError = []
        this.form.isAnotherTel = this.isAnotherTel.includes('another')
        this.form.tel = this.form.isAnotherTel ? '' : this.tel
        postClaimPatternApi(this.form)
        .then( res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            if ('messages' in result) {
              this.$store.dispatch('setErrorMessage', result.messages[0].message)
              this.$router.push({name: result.messages[0].pageId, query: {'p': this.$route.query.p}})
            } else if ('rescuePattern' in result) {
              this.$store.dispatch('setRescuePattern', result.rescuePattern)
              this.$store.dispatch('setClaimPattern', this.form)
              this.$store.dispatch('setCarrierType', result.carrierType)
              this.$router.push({name: 'rep190', query: {'p': this.$route.query.p}})
            } else {
              let claimInit = {
                tel: this.form.tel,
                isUpdate: false,
                pageId: result.pageId,
                isAnotherTel: this.form.isAnotherTel
              }
              this.$store.dispatch('setClaimInit', claimInit)
              this.$store.dispatch('setCarrierType', result.carrierType)
              this.$router.push({name: result.pageId, query: {'p': this.$route.query.p}})
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
      }
    },
    mounted () {
      this.isLoading = true
      postClaimTelListApi()
      .then( async (res) => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.telList = result.telList
        await postPassedEntryInfoApi()
        .then(res => {
          const result = res.data.result;
          this.hasPassedEntryInfo = result.hasPassedEntryInfo;
        })
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
      })
    }
  }
</script>
<style lang="scss" scoped>
  .form-checkbox {
    margin-top: 10px;
  }
  .mt10 {
    margin-top: 10px;
  }

 .button-container + .item-container {
    margin-top: 60px;
  }
</style>
