<template>
  <button class="button-close" @click="$emit('click')"><span></span>閉じる</button>
</template>

<script>
  export default {
    name: 'button-close',
    emits: ['click']
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .button-close {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid $gray-line;
    border-radius: 6px;
    font-size: 14px;
    color: $link-text-02;
    background-color: $white-01;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }

    span {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $gray-text;
      margin-right: 7px;

      &::before,
      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 2px;
        margin: -1px 0 0 -6px;
        background: $white-01;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
</style>
