<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>申請一覧</SectionHeading>
    <ContentsContainer>
      <ErrorBox v-if="isError">申請内容に不備がございますのでご確認ください。</ErrorBox>

      <ItemContainer>
        <ContentsText v-if="isEmpty">申請履歴がありません。</ContentsText>

        <template v-else>
          <ButtonToggle v-if="hasClaimUnderReview" toggle="open">
            <template v-slot:header>審査手続き中</template>
            <template v-slot:body>
              <ButtonToggleBody>
                <ItemContainer v-if="claimUnderReview.appNumber">
                  <HeadingLabel>申請番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.appNumber}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimUnderReview.appDate">
                  <HeadingLabel>申請日</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.appDate}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimUnderReview.tel">
                  <HeadingLabel>電話番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.tel}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimUnderReview.deviceName">
                  <HeadingLabel>サポート対象端末</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.deviceName}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimUnderReview.serialNo">
                  <HeadingLabel>シリアル番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimUnderReview.serialNo}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimUnderReview.serviceType">
                  <HeadingLabel>申請種別</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{serviceTypeText[claimUnderReview.serviceType]}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimUnderReview.status">
                  <HeadingLabel>申請状況</HeadingLabel>
                  <CheckText :variant="checkType[claimUnderReview.statusCode]">{{claimUnderReview.status}}</CheckText>
                  <SmallText v-if="isError && claimUnderReview.reason">{{claimUnderReview.reason}}</SmallText>
                </ItemContainer>
                <ButtonContainer v-if="isError">
                  <ButtonMain @click="goFixPage(claimUnderReview.appNumber, claimUnderReview.pageId, claimUnderReview.serviceType)" :disabled="!canPushClaimButton">不備を修正する</ButtonMain>
                  <ButtonMain variant="danger" @click="claimCancel(claimUnderReview.appNumber)" :disabled="!canPushClaimButton">申請を取消する</ButtonMain>
                </ButtonContainer>
              </ButtonToggleBody>
            </template>
          </ButtonToggle>

          <ButtonToggle v-if="hasClaimHistoryList" toggle="open">
          <template v-slot:header>申請履歴一覧</template>
          <template v-slot:body>
            <template v-for="(claimHistory, index) in claimHistoryList" :key="index">
              <ButtonToggleBody>
                <ItemContainer v-if="claimHistory.appNumber">
                  <HeadingLabel>申請番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimHistory.appNumber}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimHistory.appDate">
                  <HeadingLabel>申請日</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimHistory.appDate}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimHistory.tel">
                  <HeadingLabel>電話番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimHistory.tel}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimHistory.deviceName">
                  <HeadingLabel>サポート対象端末</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimHistory.deviceName}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimHistory.serialNo">
                  <HeadingLabel>シリアル番号</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{claimHistory.serialNo}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimHistory.serviceType">
                  <HeadingLabel>申請種別</HeadingLabel>
                  <ContentsText variant="bold" :breakword="true">{{serviceTypeText[claimHistory.serviceType]}}</ContentsText>
                </ItemContainer>
                <ItemContainer v-if="claimHistory.status">
                  <HeadingLabel>申請結果</HeadingLabel>
                  <CheckText :variant="checkType[claimHistory.statusCode]">{{claimHistory.status}}</CheckText>
                  <SmallText v-if="claimHistory.statusCode === 3 && claimHistory.reason">{{claimHistory.reason}}</SmallText>
                </ItemContainer>
              </ButtonToggleBody>
            </template>
          </template>
        </ButtonToggle>
        </template>
      </ItemContainer>

      <ButtonContainer>
        <ButtonMain :to="{name: 'rep010', params: {id: this.$route.query.p}}">戻る</ButtonMain>
      </ButtonContainer>

      <CommonSupport/>
      <ButtonContainer>
        <ButtonMain href="https://pass.auone.jp/anshin/#anshin-support">auスマートパス&emsp;マイページ</ButtonMain>
      </ButtonContainer>
    </ContentsContainer>

    <CommonLoading v-if="isLoading"/>
    <CommonFooter/>
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsContainer from '@/components/container/ContentsContainer'
import ItemContainer from '@/components/container/ItemContainer'
import HeadingLabel from '@/components/heading/HeadingLabel'
import ContentsText from '@/components/text/ContentsText'
import SmallText from '@/components/text/SmallText'
import CheckText from '@/components/text/CheckText'
import ButtonToggle from '@/components/button/ButtonToggle'
import ButtonToggleBody from '@/components/button/ButtonToggleBody'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'
import ErrorBox from '@/components/error/ErrorBox'

import {postRefClaimListApi, postClaimCancelApi} from '@/utils/ApiHelper'
import {$_resultError, $_isError} from '@/utils/Error'

export default {
  name: "rep040",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    SectionHeading,
    ContentsContainer,
    ItemContainer,
    HeadingLabel,
    ContentsText,
    SmallText,
    CheckText,
    ButtonToggle,
    ButtonToggleBody,
    ButtonContainer,
    ButtonMain,
    ErrorBox
  },
  data () {
    return {
      isLoading: false,
      claimUnderReview: {},
      claimHistoryList: [],
      canPushClaimButton: false,
      serviceTypeText: {
        1: '修理補償',
        2: '紛失補償',
        3: '修理(保険)'
      },
      checkType: {
        0: 'progress',
        1: 'error',
        2: 'error',
        3: 'error',
        4: 'error',
        5: 'success',
        6: 'success',
        7: 'error'
      },
      resultError: []
    }
  },
  computed: {
    isError () {
      // 不備 or 口座不備 or 口座情報不足
      return this.claimUnderReview.statusCode === 1 || this.claimUnderReview.statusCode === 2 || this.claimUnderReview.statusCode === 7
    },
    isEmpty () {
      return  !this.hasClaimUnderReview && !this.hasClaimHistoryList
    },
    hasClaimUnderReview() {
      return Object.keys(this.claimUnderReview).length > 0
    },
    hasClaimHistoryList() {
      return this.claimHistoryList.length > 0
    }
  },
  mounted () {
    this.refClaimList()
  },
  methods: {
    refClaimList () {
      this.isLoading = true;

      postRefClaimListApi()
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.claimUnderReview = result.claimUnderReview
        this.claimHistoryList = result.claimHistoryList
        this.canPushClaimButton = result.canPushClaimButton || false
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
      })
    },
    goFixPage(appNumber, pageId, serviceType) {
      let claimInit = {
        appNumber: appNumber,
        isUpdate: true,
        pageId: pageId,
        isAnotherTel: false
      }
      this.$store.dispatch('setClaimInit', claimInit)
      this.$router.push({name: pageId, query: {'p': this.serviceTypeToQueryParam(serviceType)}})
    },
    claimCancel(appNumber) {
      if (this.isLoading) return false
      if (!confirm('取り消しします。本当に宜しいですか？')) {
        return
      }
      this.isLoading = true;

      postClaimCancelApi(appNumber)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.refClaimList()
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
      })

    },
    serviceTypeToQueryParam(serviceType) {
      // 「不備を修正する」押下時 serviceTypeが
      // 1：修理補償の場合 → medibaフラグを修理(2)に変更する
      // 2：紛失補償の場合 → medibaフラグを紛失(1)に変更する
      // 3：修理(保険)の場合 → medibaフラグを保険(3)に変更する
      const serviceTypeQueryParamMap = {1: '2', 2: '1', 3: '3'}
      return serviceTypeQueryParamMap[serviceType] || this.$route.query.p
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
