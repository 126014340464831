<template>
  <div class="integrated-count-aggregation-period"><slot></slot></div>
</template>

<script>
export default {
  name: 'integrated-count-aggregation-period'
}
</script>

<style lang="scss" scoped>
.integrated-count-aggregation-period {
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  margin-top: 10px;
  padding: 0 10px;
}
</style>
