<template>
  <select
    v-model="selected"
    :name="name"
    :disabled="disabled"
    :class="{'is-error': error, 'is-large': large}"
    class="form-select"
  >
    <option
      v-for="(option, index) in options"
      :value="option.value"
      :disabled="option.disabled"
      :key="index"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>

export default {
  name: 'form-select',
  data() {
    return {
    }
  },
  props: {
    modelValue: { type: [String, Number], require: true},
    options: { type: Array, require: true },
    name: { type: String, require: true },
    disabled: { type: Boolean, require: false, 'default':false },
    large: { type: Boolean, require: false, 'default':false },
    error: { type: Boolean, require: false, 'default':false }
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  mounted() {
    this.selected = this.modelValue
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.form-select {
  width: calc(50% - 5px);
  height: 40px;
  background-color: $white-01;
  border: 1px solid $gray-line;
  border-radius: 5px;
  appearance: pop-up-menu;
  font-size: 14px;
  line-height: 40px;
  padding-left: 10px;
  @include mq-down(xs) {
    padding-left: 5px;
  }
  @include mq-up(sm) {
    width: 200px;
  }
  &.is-large {
    width: 100%;
    max-width: 600px;
  }
  &.is-error {
    background-color: $red-bg;
  }
}
</style>
