<template>
  <p class="check-text" :class="{['is-'+variant]:variant}">
    <span class="icon">
      <img v-if="variant === 'success'" src="@/assets/images/icon_check_white.svg" class="img" />
      <img v-if="variant === 'progress'" src="@/assets/images/icon_clock_white.svg" class="img" />
      <img v-if="variant === 'error'" src="@/assets/images/icon_close_white.svg" class="img" />
    </span>
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'check-text',
  props: {
    variant: String
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styles";
.check-text {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4;

  > .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 5px;

    > img {
      width: 8px;
      height: auto;
    }
  }

  &.is-success > .icon {
    background: $green;
  }

  &.is-progress > .icon {
    background: $yellow;
  }

  &.is-error > .icon {
    background: $red-text;
  }
}
</style>
