<template>
  <footer class="common-footer">
    <a class="link" href="https://www.kddi.com/terms/requirements/" target="_blank">動作環境、Cookie情報の利用、広告配信等について</a>
    <p class="copyright">Copyright (C) KDDI CORPORATION. <span v-if="isHoken">Copyright (C) au insurance Co., Ltd.<br/></span>All Right Reserverd.</p>
  </footer>
</template>

<script>
export default {
  name: 'common-footer',
  data() {
    return {
      isHoken: this.$store.getters.getIsHoken
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";

.common-footer {
  position: relative;
  width: 100%;
  min-height: 40px;
  padding-bottom: 15px;
}
.link {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 15px;
  color: $link-text-02;
}
.copyright {
  text-align: center;
  font-size: 10px;
  line-height: 13px;
}
</style>
