<template>
  <div class="button-kana-container">
    <ul v-for="(items, itemsIndex) in kana" :key="itemsIndex" class="button-kana-group">
      <template v-for="(item, itemIndex) in items" :key="itemIndex">
        <li v-if="item !== ''" class="button-kana" @click="$emit('click', item)">{{item}}</li>
        <li v-else class="button-kana -blank"></li>
      </template>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'button-kana',
    data() {
      return {
        kana: [
          ['ア', 'イ', 'ウ', 'エ', 'オ'],
          ['カ', 'キ', 'ク', 'ケ', 'コ'],
          ['サ', 'シ', 'ス', 'セ', 'ソ'],
          ['タ', 'チ', 'ツ', 'テ', 'ト'],
          ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
          ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'],
          ['マ', 'ミ', 'ム', 'メ', 'モ'],
          ['ヤ', '', 'ユ', '', 'ヨ'],
          ['ラ', 'リ', 'ル', 'レ', 'ロ'],
          ['ワ', '', '', '', '他']
        ]
      }
    },
    emits: ['click']
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styles";
  .button-kana-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @include mq-up(lg) {
      flex-direction: row;
    }

    .button-kana-group {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 5px 0 0;
      @include mq-up(lg) {
        flex-direction: column;
      }

      .button-kana {
        display: block;
        padding: 10px;
        border: 1px solid $gray-line;
        border-radius: 6px;
        font-size: 14px;
        color: $link-text-02;
        cursor: pointer;
        min-width: 1em;
        min-height: 1em;
        box-sizing: content-box;
        margin-right: 5px;
        margin-bottom: 0;
        @include mq-up(lg) {
          margin-right: 0;
          margin-bottom: 5px;
        }

        &:hover {
          opacity: .7;
        }

        &.-blank {
          border: 1px solid transparent;
          cursor: default;
        }
      }
    }
  }
</style>
