<template>
  <div class="img"><img :src="src" class=""></div>
</template>

<script>

export default {
  name: 'card-img',
  props: {
    src: String
  }
}
</script>

<style lang="scss" scoped>
.img {
  width: 75px;
  :deep(img) {
    width: 100%;
    height: auto;
  }
}
</style>
