<template>
  <div class="form-bank">
    <ButtonKana @click="showModal"></ButtonKana>

    <Modal ref="modal" :is-show-top-button-close="true">
      <p class="label">{{targetLabel}}をご指定ください。</p>
      <p v-if="isOffice && !this.bankCode">金融機関名をご確認ください</p>
      <p v-else-if="items.length === 0">「{{showSearchString}}」 から始まる{{targetLabel}}は見つかりませんでした。</p>
      <template v-else>
        <ul class="item" v-if="isBank">
          <li v-for="(item, index) in items" :key="index" @click="clickTag(item)"><a>{{item.bankName}}</a></li>
        </ul>
        <ul class="item" v-if="isOffice">
          <li v-for="(item, index) in items" :key="index" @click="clickTag(item)"><a>{{item.officeName}}</a></li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
  import {
    postUtilBankApi,
    postUtilBankOfficeApi
  } from '@/utils/ApiHelper'

  import ButtonKana from '@/components/button/ButtonKana'
  import Modal from "@/components/modal/Modal";

  export default {
    name: 'form-bank',
    components: {
      ButtonKana,
      Modal
    },
    props: {
      type: { type: String, default: '' },
      bankCode: { type: String, default: '' }
    },
    emits: ['select', 'toggleLoading'],
    data() {
      return {
        isShowModal: false,//追加
        items: [],
        searchString: ''
      }
    },
    computed: {
      isBank() {
        return this.type === 'bank'
      },
      isOffice() {
        return this.type === 'office'
      },
      targetLabel() {
        if (this.isBank) {
          return '金融機関'
        }
        else if (this.isOffice) {
          return '支店'
        }
        else {
          return ''
        }
      },
      otherSearchKey() {
        return '-'
      },
      showSearchString() {
        return this.searchString === this.otherSearchKey ? '他' : this.searchString
      }
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal()
        this.toggleLoading(false)
      },
      showModal(searchString) {
        this.toggleLoading(true)
        this.items = []
        this.searchString = searchString === '他' ? this.otherSearchKey : searchString
        if (this.isBank) {
          this.showBankModal()
        } else if (this.isOffice) {
          this.showBranchModal()
        }
      },
      showBankModal() {
        const formData = {
          bankName: this.searchString
        }
        postUtilBankApi(formData)
          .then(res => {
            this.items = res.data.result.bankList
            this.openModal()
          })
          .catch(() => {
            this.$router.push({ name: 'rep320' })
          })
      },
      showBranchModal() {
        if (!this.bankCode) {
          this.openModal()
          return
        }

        const formData = {
          bankCode: this.bankCode,
          officeName: this.searchString
        }
        postUtilBankOfficeApi(formData)
          .then(res => {
            this.items = res.data.result.officeList
            this.openModal()
          })
          .catch(() => {
            this.$router.push({ name: 'rep320' })
          })
      },
      clickTag(item) {
        this.hideModal()
        this.$emit('select', item)
      },
      hideModal() {
        this.$refs.modal.hideModal()
        this.searchString = ''
      },
      toggleLoading(isLoading) {
        this.$emit('toggleLoading', isLoading)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 34px;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $gray-line;
    border-bottom: 1px solid $gray-line;
    margin-bottom: 20px;

    li {
      display: flex;
      height: 53px;
      align-items: center;
      border-bottom: 1px solid $gray-line;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      a {
        font-size: 14px;
        color: $link-text-02;
        cursor: pointer;
      }
    }
  }
</style>
