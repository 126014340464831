import {postUtilUploadApi} from '@/utils/ApiHelper'
import {$_validationErrors, $_errorMessages} from '@/utils/Error'

export function $_setUploadImageFile (fileList, el, form, target) {
  const file = fileList[0]

  if(file) {
    const key = el.name
    const fileSize = file.size / 1048576
    const message = '4MB未満のファイルを指定してください'
    const index = target.resultError.findIndex(e => e.errorItem === key && e.fileError)

    if (fileSize < 4) {
      if (index > -1) {
        target.resultError.splice(index, 1);
        $_checkErrorMessage(target)
      }
      $_setUploadImagePath(file, key, form, target)
    } else {
      target.form[key] = null
      target.file[key] = null
      form.reset();
      if (index > -1) {
        target.resultError[index].errorMessage = message
      } else {
        target.resultError.push({
          errorItem: key,
          errorMessage: message,
          fileError: true
        })
        $_checkErrorMessage(target)
      }
    }
  }
}

export function $_setUploadImagePath (file, key, form, target) {
  target.isLoading = true
  postUtilUploadApi(file)
  .then(res => {
    const data = res.data
    const result = data.result
    if (result && result.errors) {
      // エラーは握り潰す
      form.reset();
    } else {
      target.form[key] = result.filePath
      target.file[key] = file
    }
    target.isLoading = false
  })
  .catch(() => {
    const message = 'ファイルのアップロードに失敗しました。※再度撮影しファイルをアップロードすると成功する場合がございます。ご不明の場合はお問合せください。'

    target.form[key] = null
    target.file[key] = null
    form.reset();
    target.resultError.push({
      errorItem: key,
      errorMessage: message,
      fileError: true
    })
    $_checkErrorMessage(target)
    target.isLoading = false
  })
}

export function $_fileName(file) {
  return file ? file.name : ''
}

export function $_checkErrorMessage(target) {
  if($_validationErrors(target.resultError).length > 0) {
    if($_errorMessages(target.resultError).length === 0) {
      target.resultError.push({
        errorMessage : "入力内容に問題があるため申請を進めることができません。各入力欄のメッセージをご確認ください。"
      })
    }
  } else {
    if($_errorMessages(target.resultError).length > 0) {
      target.resultError = []
    }
  }
}
