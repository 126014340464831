<template>
  <small class="small-text" :class="{['is-'+variant]:variant}"><slot></slot></small>
</template>

<script>
export default {
  name: 'small-text',
  props: {
    variant: String
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styles";
.small-text {
  font-size: 12px;
  line-height: 1.4;
  color: $gray-text-02;
  &:before {
    content: '※';
  }
  &.is-non-asterisk {
    &:before {
      content: none;
    }
  }
  a {
    cursor: pointer;
    color: $gray-text;
    text-decoration: underline;
  }
  &.is-red {
    color:$red-text;
  }
}
</style>
