<template>
  <div class="common-loading">
    <img src="@/assets/images/loading.gif" alt="loading">
	</div>
</template>

<script>

export default {
  name: 'common-loading'
}
</script>

<style lang="scss" scoped>
.common-loading{
	position: fixed;
	top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .8);

  img {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }
}
</style>
