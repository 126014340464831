<template>
  <div class="button-toggle" :class="{'-transition':transition}">
    <div class="box" @click="accordionToggle">
      <p class="header"><slot name="header"></slot></p>
      <div class="icon">
        <img v-if="!isOpened" src="@/assets/images/icon_arrow_black_bottom.svg" class="img" />
        <img v-else src="@/assets/images/icon_arrow_black_top.svg" class="img" />
      </div>
    </div>
    <transition name="js-accordion" @enter="enter" @after-enter="afterEnter" @leave="leave">
      <div v-show="isOpened" class="body">
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'button-toggle',
    props: {
      toggle: { type: String, default: "" }
    },
    data () {
      return {
        isOpened: false,
        transition: false,
        target: undefined
      }
    },
    mounted: function () {
      if (this.toggle === 'open') {
        this.isOpened = true
      }
      window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      accordionToggle: function () {
        this.transition = true
        this.isOpened = !this.isOpened
      },
      open: function () {
        this.isOpened = true
      },
      enter: function (el) {
        if (!this.target) {
          this.target = el;
        }

        el.style.height = '0'
        setTimeout(() => { el.style.height = el.scrollHeight + 'px' })
      },
      afterEnter: function (el) {
        el.style.height = 'auto'
      },
      leave: function (el) {
        el.style.height = el.scrollHeight + 'px'
        setTimeout(() => { el.style.height = '0' })
      },
      handleResize: function () {
        if (this.isOpened) {
          this.target.style.height = ''
          this.target.style.height = this.target.scrollHeight + 'px'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .button-toggle {
    position: relative;
    border: 1px solid $gray-line;
    box-shadow: 0 2px 0 $gray-02;
    width: 100%;

    + .button-toggle {
      margin-top: 15px;
    }

    > .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
      background: $white-01;
      cursor: pointer;

      .header {
        width: calc(100% - 32px);
        color: $black-02;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }

      .icon {
        img {
          width: 12px;
          margin-top: 8px;
        }
      }
    }

    > .body {
      padding: 0 20px;
      overflow: hidden;
    }

    &.-transition {
      > .body {
        transition: height 0.4s ease-in-out;
      }
    }
  }

  .js-accordion-enter,
  .js-accordion-leave-to {
    height: 0;
  }
</style>
