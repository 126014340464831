<template>
  <textarea
    v-model="text"
    :name="name"
    :placeholder="placeholder"
    :rows="rows"
    :cols="cols"
    :disabled="disabled"
    :class="{'is-error': error}"
    class="form-textfield"
  ></textarea>
</template>

<script>

export default {
  name: 'form-textarea',
  props: {
    modelValue: { type: String, require: true },
    name: { type: String, require: true },
    placeholder: { type: String, require: false },
    rows: { type: Number, require: false },
    cols: { type: Number, require: false },
    disabled: { type: Boolean, require: false, 'default':false },
    error: { type: Boolean, require: false, 'default':false }
  },
  emits: ['update:modelValue'],
  computed: {
    text: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.form-textfield {
  width: 100%;
  max-width: 600px;
  background-color: $white-01;
  border-radius: 5px;
  border: 1px solid $gray-line;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.4;
  &:disabled {
		background-color: $gray-line;
  }
  &.is-error {
    background-color: $red-bg;
  }
}
</style>
