<template>
  <p class="contents-text" :class="{['is-'+variant]:variant, 'is-break-word':breakword}"><slot></slot></p>
</template>

<script>
export default {
  name: 'contents-text',
  props: {
    variant: { type: String, require: false},
    breakword: { type: Boolean, require: false, 'default':false }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styles";
.contents-text {
  font-size: 15px;
  line-height: 1.4;
  > :deep(.small-text) {
    display: block;
  }
  > :deep(span.important) {
    color: $orange-02;
  }
  &.is-bold {
    font-weight: bold;
  }
  :deep(a) {
    color: $black-01;
    text-decoration: underline;
  }
  &.is-break-word {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
</style>
