<template>
  <MainContainer>
    <CommonHeader />

    <div v-show="isEdit">
      <SectionHeading>口座情報入力</SectionHeading>
      <ContentsContainer>
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>
        <ContentsText>指定口座のご入力をお願いいたします。振込完了まで、1-2週間お待ちください。</ContentsText>
      </ContentsContainer>

      <ContentsContainer>
        <ItemContainer>
          <HeadingLabel>金融機関名</HeadingLabel>
          <FormRadio
            v-model="bankType"
            name="bankType"
            :options="bankTypeOption"
            @input="selectBankType"
          ></FormRadio>

          <template v-if="!form.isJapanPostBank">
            <FormMajorBank @select="selectBank"/>
            <ContentsText>金融機関名の最初の文字を以下の50音検索から選択してください。記載がない場合は、【他】を選択してください</ContentsText>
            <FormBank type="bank" @select="selectBank" @toggleLoading="toggleLoading"/>
            <ErrorMessage v-if="'bankCode' in errorMessage">{{errorMessage.bankCode}}</ErrorMessage>
          </template>
        </ItemContainer>

        <ItemContainer v-if="!form.isJapanPostBank">
          <HeadingLabel>支店名</HeadingLabel>
          <ContentsText>支店名の最初の文字を以下の50音検索から選択してください。記載がない場合は、【他】を選択してください</ContentsText>
          <FormBank type="office" :bank-code="form.bankCode" @select="selectOffice" @toggleLoading="toggleLoading"/>
          <ErrorMessage v-if="'officeNumber' in errorMessage">{{errorMessage.officeNumber}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>口座種別</HeadingLabel>
          <FormRadio
            v-model="userType"
            name="userType"
            :options="userTypeOption"
            @input="selectUserType"
          ></FormRadio>
        </ItemContainer>

        <ItemContainer v-if="!form.isJapanPostBank">
          <HeadingLabel>預金種別</HeadingLabel>
          <FormRadio
            v-model="form.depositType"
            name="depositType"
            :options="depositTypeOption"
          ></FormRadio>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>口座名義（カナ）</HeadingLabel>
          <template v-if="form.isCorporation">
            <FormInput
              v-model="form.accountNameSei"
              name="accountNameSei"
              type="text"
              placeholder="カ）ケ－デイ－デイ－アイ"
              :error="'accountNameSei' in errorMessage"
              maxlength="30"
            ></FormInput>
            <ErrorMessage v-if="'accountNameSei' in errorMessage">{{errorMessage.accountNameSei}}</ErrorMessage>
          </template>
          <template v-else>
            <ContentsText>セイ</ContentsText>
            <FormInput
              v-model="form.accountNameSei"
              name="accountNameSei"
              type="text"
              placeholder="エイユウ"
              :error="'accountNameSei' in errorMessage"
              maxlength="15"
            ></FormInput>
            <ErrorMessage v-if="'accountNameSei' in errorMessage">{{errorMessage.accountNameSei}}</ErrorMessage>
            <ContentsText>メイ</ContentsText>
            <FormInput
              v-model="form.accountNameMei"
              name="accountNameMei"
              type="text"
              placeholder="タロウ"
              :error="'accountNameMei' in errorMessage"
              maxlength="15"
            ></FormInput>
            <ErrorMessage v-if="'accountNameMei' in errorMessage">{{errorMessage.accountNameMei}}</ErrorMessage>
          </template>
        </ItemContainer>

        <ItemContainer v-if="form.isJapanPostBank">
          <HeadingLabel>記号</HeadingLabel>
          <FormInput
            v-model="form.officeNumber"
            name="officeNumber"
            type="text"
            placeholder="12345"
            maxlength="5"
            :error="'officeNumber' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'officeNumber' in errorMessage">{{errorMessage.officeNumber}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>{{form.isJapanPostBank ? '番号' : '口座番号［半角数字］'}}</HeadingLabel>
          <ContentsText v-if="!form.isJapanPostBank"><SmallText>数字７桁で入力してください</SmallText></ContentsText>
          <FormInput
            v-model="form.accountNumber"
            name="accountNumber"
            type="text"
            :placeholder="form.isJapanPostBank ? '12345678' : '1234567'"
            :maxlength="form.isJapanPostBank ? '8' : '7'"
            :error="'accountNumber' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'accountNumber' in errorMessage">{{errorMessage.accountNumber}}</ErrorMessage>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="next" @click="confirm">口座情報確認画面へ</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
        <bank-info-box v-if="!form.isJapanPostBank" :bank-name="bankName" :office-name="officeName"/>
      </ContentsContainer>
    </div>

    <div v-show="isConfirm">
      <SectionHeading>口座情報の確認</SectionHeading>
      <ContentsContainer>
        <ContentsText>次の内容で申請します。</ContentsText>
        <ItemContainer>
          <HeadingLabel>金融機関名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{bankName}}</ContentsText>
        </ItemContainer>
        <ItemContainer v-if="!form.isJapanPostBank">
          <HeadingLabel>支店名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{officeName}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>口座種別</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{userTypeText}}</ContentsText>
        </ItemContainer>
        <ItemContainer v-if="!form.isJapanPostBank">
          <HeadingLabel>預金種別</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{depositTypeText}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>口座名義（カナ）</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.accountNameSei}}{{form.accountNameMei}}</ContentsText>
        </ItemContainer>
        <ItemContainer v-if="form.isJapanPostBank">
          <HeadingLabel>記号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.officeNumber}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>{{form.isJapanPostBank ? '番号' : '口座番号'}}</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.accountNumber}}</ContentsText>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="next" @click="submit">申請する</ButtonMain>
          <ButtonMain @click="edit">修正する</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <CommonFooter />
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
import {
  postClaimInitApi,
  postClaimAccountCheckApi,
  postClaimAccountInsertApi
} from '@/utils/ApiHelper'
import {$_resultError, $_validationErrors, $_validationErrorMessage, $_errorMessages, $_isError} from '@/utils/Error'

import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonLoading from '@/components/common/CommonLoading'
import CommonSupport from '@/components/common/CommonSupport'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import ItemContainer from '@/components/container/ItemContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import HeadingLabel from '@/components/heading/HeadingLabel'
import ContentsText from '@/components/text/ContentsText'
import SmallText from '@/components/text/SmallText'
import FormRadio from '@/components/select/FormRadio'
import FormMajorBank from '@/components/form/FormMajorBank'
import FormBank from '@/components/form/FormBank'
import FormInput from '@/components/form/FormInput'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'
import ErrorBox from '@/components/error/ErrorBox'
import ErrorMessage from '@/components/error/ErrorMessage'
import BankInfoBox from '@/components/text/BankInfoBox'

export default {
  name: "rep150",
  components: {
    BankInfoBox,
    CommonHeader,
    CommonFooter,
    CommonLoading,
    CommonSupport,
    MainContainer,
    ContentsContainer,
    ItemContainer,
    SectionHeading,
    HeadingLabel,
    ContentsText,
    SmallText,
    FormRadio,
    FormMajorBank,
    FormBank,
    FormInput,
    ButtonMain,
    ButtonContainer,
    ErrorBox,
    ErrorMessage
  },
  data() {
    return {
      status: 'edit',
      bankType: '1',
      bankName: '',
      officeName: '',
      userType: '1',
      form: {
        registrationReservationId: '',
        appNumber: '',
        accountNameSei: '',
        accountNameMei: '',
        bankCode: '',
        officeNumber: '',
        depositType: 1,
        accountNumber: '',
        isCorporation: false,
        isJapanPostBank: false
      },
      basicInfo: {},
      modelList: [],
      isLoading: false,
      timer: null,
      resultError: []
    }
  },
  created () {
    this.setRedirectTimer()
    window.addEventListener('beforeunload', this.beforeunload)
  },
  beforeDestroy() {
    this.clearRedirectTimer()
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.beforeunload)
  },
  mounted() {
    if (this.isUpdate) {
      this.isLoading = true
      postClaimInitApi().then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if (!$_isError(this.resultError, this.$route)) {
          this.form.registrationReservationId = result.registrationReservationId
        }
        this.form.appNumber = this.appNumber
        this.isLoading = false
      })
    } else {
      this.form.registrationReservationId = this.$store.getters.getRegistrationReservationId
    }
  },
  computed: {
    isUpdate () {
      return this.$store.getters.getIsUpdate
    },
    bankTypeOption() {
      let options = []
      options.push({label: 'ゆうちょ銀行以外', value: '1'})
      options.push({label: 'ゆうちょ銀行', value: '2'})
      return options
    },
    userTypeOption() {
      let options = []
      options.push({label: '個人', value: '1'})
      options.push({label: '法人', value: '2'})
      return options
    },
    userTypeText() {
      return this.userTypeOption.find(option => option.value === this.userType).label
    },
    depositTypeOption() {
      let options = []
      options.push({label: '普通', value: 1})
      options.push({label: '当座', value: 2})
      return options
    },
    depositTypeText() {
      return this.depositTypeOption.find(option => option.value === this.form.depositType).label
    },
    appNumber () {
      return this.$store.getters.getAppNumber
    },
    isEdit() {
      return this.status === 'edit'
    },
    isConfirm() {
      return this.status === 'confirm'
    },
    errors() {
      return $_validationErrors(this.resultError)
    },
    isError() {
      return this.errors.length > 0
    },
    errorMessage() {
      return $_validationErrorMessage(this.resultError)
    },
    errorMessages () {
      return $_errorMessages(this.resultError)
    }
  },
  methods: {
    beforeunload (event) {
      event.returnValue = ''
    },
    selectBankType(event) {
      const bankType = event.target.value
      if (bankType === '2') {
        this.form.isJapanPostBank = true
        this.form.depositType = 0
        this.bankName = 'ゆうちょ銀行'
        this.form.bankCode = '9900'
      } else {
        this.form.isJapanPostBank = false
        this.form.depositType = 1
        this.bankName = ''
        this.form.bankCode = ''
      }
      this.userType = '1'
      this.form.isCorporation = false
      this.form.accountNameMei = ''
      this.form.accountNameSei = ''
      this.officeName = ''
      this.form.officeNumber = ''
      this.form.accountNumber = ''
      this.resultError = []
    },
    selectBank(bank) {
      this.bankName = bank.bankName
      this.form.bankCode = bank.bankCode
      this.officeName = ''
      this.form.officeNumber = ''
    },
    selectOffice(office) {
      this.officeName = office.officeName
      this.form.officeNumber = office.officeCode
    },
    selectUserType(event) {
      const userType = event.target.value
      this.form.isCorporation = userType === '2'
      this.form.depositType = 1
      this.form.accountNameSei = ''
      this.form.accountNameMei = ''
      this.form.accountNumber = ''
      this.resultError = []
    },
    confirm () {
      if (this.isLoading) return false

      this.toggleLoading(true)
      this.resultError = []
      postClaimAccountCheckApi(this.form)
        .then(res => {
          const data = res.data
          const result = data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.status = 'confirm'
          }
          this.clearRedirectTimer()
          this.setRedirectTimer()
          this.scrollTop()
          this.toggleLoading(false)
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
    },
    submit () {
      if (this.isLoading) return false

      this.toggleLoading(true)
      postClaimAccountInsertApi(this.form)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.appNumber)
            this.$router.push({ name: 'rep160', query: {'p': this.$route.query.p} })
          }
          this.toggleLoading(false)
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
    },
    edit() {
      this.scrollTop()
      this.clearRedirectTimer()
      this.setRedirectTimer()
      this.status = 'edit'
    },
    scrollTop() {
      this.$scrollTo('body', 1)
    },
    toggleLoading(isLoading) {
      this.isLoading = isLoading
    },
    setRedirectTimer() {
      this.timer = setTimeout(this.timeoutErrorPage, 900000);
    },
    timeoutErrorPage() {
      this.$router.push({name: 'rep340', query: {'p': this.$route.query.p}})
    },
    clearRedirectTimer() {
      clearTimeout(this.timer);
    }
  }
}
</script>
<style lang="scss" scoped>
  .main-container {
    padding-bottom: 176px;
  }
  .common-footer {
    bottom: 66px;
  }
</style>