<template>
  <div class="integrated-count-number">
    <slot></slot>
    <span v-if="(count !== null) && hasLostRight">{{count}}回</span>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: 'integrated-count-number',
  props: {
    count: Number,
    hasLostRight: Boolean
  }
}
</script>

<style lang="scss" scoped>
.integrated-count-number {
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  padding: 0 10px;
  span {
    font-size: 24px;
  }
}
</style>
