<template>
  <p class="message-box"><span class="inner"><slot></slot></span></p>
</template>

<script>

export default {
  name: 'message-box'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styles";
.message-box {
  padding: 0 10px;
  max-width: 600px;

  > .inner {
    display: block;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.4;
    color: $black-02;
    background-color: $gray-bg;
    border-radius: 3px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
</style>
