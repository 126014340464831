<template>
  <component :is="to ? 'router-link' : href ? 'a' : 'span'" class="inline-link" :href="href" :to="to" @click="click" :target="target">
    <span><slot></slot></span>
    <img v-if="target === '_blank'" src="@/assets/images/icon_link_out_blue.svg">
  </component>
</template>

<script>
  export default {
    name: 'inline-link',
    props: {
      to: { type: String, default: '' },
      href: { type: String, default: '' },
      target: { type: String, default: '' }
    },
    emits: ['click'],
    methods: {
      click: function (e) {
        this.$emit('click', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styles";
  .inline-link {
    cursor: pointer;
    > span {
      color: $link-text-02;
      text-decoration: underline;
    }
    > img {
      height: .8em;
      margin: 0 .2em;
      position: relative;
      top: .1em;
    }
  }
</style>
