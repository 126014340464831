<template>
  <div class="border-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'border-container'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.border-container {
  border: 1px solid $gray-line;
  padding: 15px;
  + .border-container {
    margin-top: 25px;
  }
  :deep(.item-container) {
    + .right-count-container {
      margin-top: 25px;
    }
  }
}
</style>
