const axios = require('axios');
const FormData = require('form-data');
const COOKIES_VTKT = 'VTKT'
const TIMEOUT_LIMIT = 30000; // ms 30秒
import store from '@/store'
import router from '@/router'


// メニュー画面表示用API を実行
export function postInitMenuApi () {
  return new Promise(function (resolve, reject) {
    postApi('/', {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}


// 端末情報一覧画面表示用API を実行
export function postRefEntryListApi () {
  return new Promise(function (resolve, reject) {
    postApi('/ref/entry/list', {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 申請一覧画面表示用API を実行
export function postRefClaimListApi () {
  return new Promise(function (resolve, reject) {
    postApi('/ref/claim/list', {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 回数チェック結果参照画面表示用API を実行
export function postRefRightCountApi () {
  return new Promise(function (resolve, reject) {
    postApi('/ref/right/count', {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 回線選択画面表示用API を実行
export function postClaimTelListApi () {
  return new Promise(function (resolve, reject) {
    postApi('/claim/tel/list', {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 補償タイプ判定API を実行
export function postClaimPatternApi (data) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/pattern', data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 申請前処理API を実行
export function postClaimInitApi (needsModelList = true, needsStoreList = true) {
  return new Promise(function (resolve, reject) {
    const data = {
      ...store.getters.getClaimInit,
      needsModelList: needsModelList,
      needsStoreList: needsStoreList
    }
    postApi('/claim/init', data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 申請端末情報取得API を実行
export function postSelectDeviceListApi (includeOpenKosapo, isFromEntry) {
  return new Promise(function (resolve, reject) {
    const data = {
      includeOpenKosapo: includeOpenKosapo,
      isFromEntry: isFromEntry
    }
    postApi('/claim/select/device/list', data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 事前申請情報(合格済み)有無判定API
export function postPassedEntryInfoApi () {
  return new Promise(function (resolve, reject) {
    postApi('/claim/select/entry/pass').then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

//////////////
// 紛失申請
//////////////

// 紛失申請情報取得API を実行
export function postClaimLostApi (registrationReservationId = '') {
  return new Promise(function (resolve, reject) {
    const appNumber = store.getters.getAppNumber
    const data = {registrationReservationId: registrationReservationId}
    postApi(`/claim/lost/${appNumber}`, data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 紛失申請内容チェックAPI を実行
export function postClaimLostCheckApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/lost/check`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 紛失申請登録API を実行
export function postClaimLostInsertApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/lost/insert`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 紛失申請更新API を実行
export function postClaimLostUpdateApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/lost/update`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

//////////////
// 修理申請
//////////////

// 修理申請情報取得API を実行
export function postClaimRepairApi (registrationReservationId) {
  return new Promise(function (resolve, reject) {
    const appNumber = store.getters.getAppNumber
    const data = {registrationReservationId: registrationReservationId}
    postApi(`/claim/repair/${appNumber}`, data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 修理申請内容チェックAPI を実行
export function postClaimRepairCheckApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/repair/check`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 修理申請登録API を実行
export function postClaimRepairInsertApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/repair/insert`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 修理申請更新API を実行
export function postClaimRepairUpdateApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/repair/update`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

//////////////
// スマパス2年限定申請
//////////////

// スマパス2年限定申請情報取得API を実行
export function postClaimSma2Api (registrationReservationId) {
  return new Promise(function (resolve, reject) {
    const appNumber = store.getters.getAppNumber
    const data = {registrationReservationId: registrationReservationId}
    postApi(`/claim/sma2/${appNumber}`, data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// スマパス2年限定申請内容チェックAPI を実行
export function postClaimSma2CheckApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/sma2/check`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// スマパス2年限定申請登録API を実行
export function postClaimSma2InsertApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/sma2/insert`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// スマパス2年限定申請更新API を実行
export function postClaimSma2UpdateApi(formData) {
  return new Promise(function (resolve, reject) {
    postApi(`/claim/sma2/update`, formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}


//////////////
// 保険申請
//////////////

// 保険申請情報取得API を実行
export function postClaimInsuranceApi (registrationReservationId) {
  return new Promise(function (resolve, reject) {
    const appNumber = store.getters.getAppNumber
    const data = {registrationReservationId: registrationReservationId}
    postApi(`/claim/insurance/${appNumber}`, data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}


// 保険申請内容チェックAPI を実行
export function postClaimInsuranceCheckApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/insurance/check', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 保険申請登録API を実行
export function postClaimInsuranceInsertApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/insurance/insert', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}


// 保険申請更新API を実行
export function postClaimInsuranceUpdateApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/insurance/update', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

//////////////
// 事前申請
//////////////

// 事前申請情報取得API を実行
export function postClaimEntryApi (registrationReservationId) {
  return new Promise(function (resolve, reject) {
    const advanceEntryNumber = store.getters.getAdvanceEntryNumber
    const data = {registrationReservationId: registrationReservationId}
    postApi(`/claim/entry/${advanceEntryNumber}`, data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 事前申請内容チェックAPI を実行
export function postClaimEntryCheckApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/entry/check', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 事前申請登録API を実行
export function postClaimEntryInsertApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/entry/insert', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 事前申請更新API を実行
export function postClaimEntryUpdateApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/entry/update', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}


// 口座情報内容チェックAPI
export function postClaimAccountCheckApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/account/check', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 口座情報登録API
export function postClaimAccountInsertApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/claim/account/insert', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 申請取り消しAPI を実行
// 事前申請の場合は、isEntry: true, それ以外の場合は、false
export function postClaimCancelApi (appNumber, isEntry = false) {
  return new Promise(function (resolve, reject) {
    const data = {isEntry: isEntry}
    postApi(`/claim/cancel/${appNumber}`, data).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 銀行名取得API
export function postUtilBankApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/util/bank', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 支店名取得API
export function postUtilBankOfficeApi (formData) {
  return new Promise(function (resolve, reject) {
    postApi('/util/bank/office', formData).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// 認証API
export function postUtilAuthApi () {
  return new Promise(function (resolve, reject) {
    postApi('/util/auth', {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}

// ファイルアップロードAPI を実行
export function postUtilUploadApi (data) {
  return new Promise(function (resolve, reject) {
    const apiPath = "/util/upload"
    const url = `${process.env.VUE_APP_BASE_API_URL}${apiPath}`
    const headers = {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
    let body = {vtkt: getVtktCookie()}
    let formData = new FormData();
    formData.append('body', JSON.stringify(body));
    formData.append('imageFile', data);
    axios.post(url, formData, {headers: headers, timeout: TIMEOUT_LIMIT})
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      if ((err.message).indexOf('timeout of') === 0) {
        return router.push({name: 'rep330'})
      }
      reject(err)
    })
  })
}

// APIを実行する(Content-Type': 'application/json')
function postApi (apiPath, requestData) {
  return new Promise(function (resolve, reject) {
    const headers = {
      'accept': 'application/json',
      'Content-Type': 'application/json',

    }
    const url = `${process.env.VUE_APP_BASE_API_URL}${apiPath}`
    const vtkt = getVtktCookie()
    const data = {vtkt: vtkt, ...requestData}
    axios.post(url, data, {headers: headers, timeout: TIMEOUT_LIMIT})
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        if ((err.message).indexOf('timeout of') === 0) {
          return router.push({name: 'rep330'})
        }
        reject(err)
      })
  })
}

// // リクエストパラメータのValueをStringにキャスト
// function dataValueToString(data) {
//   for (let key in data) {
//     if (data[key]) {
//       data[key] = data[key].toString()
//     }
//   }
//   return data
// }


// $cookiesからuser_sessionを取得
function getVtktCookie () {
  if (window.$cookies.isKey(COOKIES_VTKT)) {
    return window.$cookies.get(COOKIES_VTKT)
  }
  return ""
}


// TODO削除する
// init API を実行(ph1.5)
export function postInitApi () {
  return new Promise(function (resolve, reject) {
    postApi(`/init`, {}).then(res => { resolve(res) }).catch(error => { reject(error) })
  })
}
// claim/insert/check API を実行(ph1.5)
export function postClaimInsertCheckApi (data) {
  return new Promise(function (resolve, reject) {
    const apiPath = "/claim/insert/check"
    const url = `${process.env.VUE_APP_BASE_API_URL}${apiPath}`
    const headers = {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
    let body = {...data.body, vtkt: getVtktCookie()}
    let formData = new FormData();
    formData.append('body', JSON.stringify(body));

    let images = data.images
    for (let key in images) {
      formData.append(key, images[key]);
    }
    axios.post(url, formData, headers)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}


// claim/insert/ API を実行(ph1.5)
export function postClaimInsertApi (data) {
  return new Promise(function (resolve, reject) {
    const apiPath = "/claim/insert/"
    const url = `${process.env.VUE_APP_BASE_API_URL}${apiPath}`
    const headers = {
      'accept': 'application/json',
      'Content-Type': 'multipart/form-data'
    }
    let body = {...data.body, vtkt: getVtktCookie()}
    let formData = new FormData();
    formData.append('body', JSON.stringify(body));

    let images = data.images
    for (let key in images) {
      formData.append(key, images[key]);
    }
    axios.post(url, formData, headers)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// TODO削除する
