<template>
  <div class="right-count-box">
    <p class="title"><slot></slot></p>
    <div class="body">
      <p v-if="count !== null"><span class="count">{{count}}</span>回</p>
      <p v-else>対象外</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'right-count-box',
    props: {
      count: Number
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styles";
  .right-count-box {
    width: 100%;
    max-width: 165px;
    background: $gray-thin;
    border: 2px solid $orange-02;
    .title {
      background: $orange-02;
      padding: 9px 8px;
      font-size: 15px;
      line-height: 15px;
      color: $white-01;
    }
    .body {
      height: 78px;
      font-size: 15px;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .count {
        font-size: 55px;
        line-height: 45px;
        margin: 0 5px 0 11px;
      }
    }
  }
</style>
