<template>
  <div class="integrated-count-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'integrated-count-container'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.integrated-count-container {
  border: 1px solid $orange-01;
  margin-top: 20px;
}
</style>
