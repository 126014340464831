<template>
  <dl class="list">
    <div v-for="item in list" :key="item.label" class="item">
      <dt class="label">{{item.label}}</dt>
      <dd class="data">{{item.data}}</dd>
    </div>
  </dl>
</template>

<script>

export default {
  name: 'card-list',
  props: {
    list: Array
  }
}
</script>
<style lang="scss" scoped>
.list {
  .item {
    display: flex;
    align-items: center;
    + .item {
      margin-top: 4px;
    }
    .label {
      width: 60px;
      font-size: 12px;
      line-height: 21px;
      margin-right: 16px;
      &:not(:nth-of-type(1)) {
        margin-top: 6px;
      }
    }
    .data {
      width:calc(100% - 76px);
      font-size: 15px;
      line-height: 21px;
      &:not(:nth-of-type(1)) {
        margin-top: 6px;
      }
    }
  }
}
</style>
