import { createStore } from "vuex";

export default createStore({
  state: {
    claimInit: {
      tel: "",
      deviceId: "",
      appNumber: "",
      advanceEntryNumber: "",
      isUpdate: false,
      isAnotherTel: false
    },
    claimPattern: {
      tel: "",
      isAnotherTel: false,
      referrerInfo: ""
    },
    rescuePattern: "",
    carrierType: "",
    registrationReservationId: "",
    receiptNumber: "",
    isHoken: false,
    errorMessage: ""
  },
  getters: {
    getClaimInit(state) {
      return state.claimInit || {}
    },
    getClaimPattern(state) {
      return state.claimPattern || {}
    },
    getIsUpdate(state) {
      return state.claimInit.isUpdate
    },
    getAdvanceEntryNumber (state) {
      return state.claimInit.advanceEntryNumber || ""
    },
    getAppNumber (state) {
      return state.claimInit.appNumber || ""
    },
    getRescuePattern (state) {
      return state.rescuePattern || ""
    },
    getCarrierType (state) {
      return state.carrierType || ""
    },
    getReceiptNumber (state) {
      return state.receiptNumber || ""
    },
    getIsHoken (state) {
      return state.isHoken
    },
    getRegistrationReservationId (state) {
      return state.registrationReservationId || ""
    },
    getErrorMessage (state) {
      return state.errorMessage || ""
    }
  },
  mutations: {
    setClaimInit(state, claimInit) {
      state.claimInit = claimInit
    },
    setClaimPattern(state, claimPattern) {
      state.claimPattern = claimPattern
    },
    setReceiptNumber(state, receiptNumber) {
      state.receiptNumber = receiptNumber || ""
    },
    setRescuePattern (state, rescuePattern) {
      state.rescuePattern = rescuePattern || ""
    },
    setCarrierType (state, carrierType) {
      state.carrierType = carrierType || ""
    },
    setIsHoken (state, isHoken) {
      state.isHoken = isHoken
    },
    setRegistrationReservationId (state, registrationReservationId) {
      state.registrationReservationId = registrationReservationId || ""
    },
    setErrorMessage (state, errorMessage) {
      state.errorMessage = errorMessage || ""
    },
  },
  actions: {
    setClaimInit({commit}, claimInit) {
      commit('setClaimInit', claimInit)
    },
    setClaimPattern({commit}, claimPattern) {
      commit('setClaimPattern', claimPattern)
    },
    setReceiptNumber ({commit}, receiptNumber) {
      commit('setReceiptNumber', receiptNumber)
    },
    setRescuePattern ({commit}, rescuePattern) {
      commit('setRescuePattern', rescuePattern)
    },
    setCarrierType ({commit}, carrierType) {
      commit('setCarrierType', carrierType)
    },
    setIsHoken ({commit}, isHoken) {
      commit('setIsHoken', isHoken)
    },
    setRegistrationReservationId ({commit}, registrationReservationId) {
      commit('setRegistrationReservationId', registrationReservationId)
    },
    setErrorMessage ({commit}, errorMessage) {
      commit('setErrorMessage', errorMessage)
    },
  }
})
