<template>
  <div class="main-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'main-container'
}
</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
  min-height: 100%;
  padding-bottom: 110px;
}
:deep(.section-heading) + .contents-container {
  margin-top: 20px;
}
:deep(.contents-container) {
  + .section-heading {
    margin-top: 25px;
  }
  + .contents-container {
    margin-top: 30px;
  }
}
:deep(.common-footer) {
  position: absolute;
  bottom: 0;
}
</style>
