<template>
  <h2 class="contents-heading"><span class="inner"><slot></slot></span></h2>
</template>

<script>
export default {
  name: 'contents-heading'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.contents-heading {
  width: 100%;
  padding-bottom: 9px;
  border-bottom: 1px solid $gray-line;

  > .inner {
    display: block;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    border-left: 3px solid $orange-02;
    padding-left: 7px;
  }
}
</style>
