<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'card-container',
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    @include mq-up(sm) {
      max-width: 320px;
    }
    
    :deep(.list) {
      margin-left: 16px;
    }
  }
</style>
