<template>
  <div class="integrated-device-text"><slot></slot></div>
</template>

<script>
export default {
  name: 'integrated-device-text',
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
.integrated-device-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  margin-top: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
