<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>&nbsp;</SectionHeading>
    <ContentsContainer>
      <ContentsText>404 NotFound<br>お探しのページが見つかりません。 </ContentsText>
      <CommonSupport />
      <ButtonContainer>
        <ButtonMain href="https://pass.auone.jp/anshin/#anshin-support">auスマートパス&emsp;マイページ</ButtonMain>
      </ButtonContainer>
    </ContentsContainer>
    <CommonFooter/>
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsText from '@/components/text/ContentsText'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'

export default {
  name: "rep310",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    MainContainer,
    ContentsContainer,
    SectionHeading,
    ContentsText,
    ButtonContainer,
    ButtonMain
  }
}
</script>
<style lang="scss" scoped>
</style>
