<template>
  <li class="integrated-count-box-child"><slot></slot></li>
</template>

<script>
export default {
  name: 'integrated-count-box-child'
}
</script>

<style lang="scss" scoped>
.integrated-count-box-child {
  width: 50%;
}
</style>
