<template>
    <div class="contents-text-wrap" :class="{['is-'+variant]:variant, 'is-break-word':breakword}"><slot></slot></div>
  </template>
  
  <script>
  export default {
    name: 'contents-text-wrap',
    props: {
      variant: { type: String, require: false},
      breakword: { type: Boolean, require: false, 'default':false },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/stylesheets/styles";
  .contents-text-wrap {
    margin-bottom: 35px;
    :deep(.contents-text) {
        margin-top: 10px;
    }
  }
  </style>
  