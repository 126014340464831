<template>
  <p class="note"><slot></slot></p>
</template>

<script>
export default {
  name: 'card-note'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.note {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 6px;
  border: 1px solid $black-01;
  font-size: 12px;
  line-height: 17px;
  @include mq-up(sm) {
    max-width: 320px;
  }
  :deep(a) {
    color: inherit;
  }
}
</style>
