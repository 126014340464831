<template>
  <div class="button-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'button-container'
}
</script>

<style lang="scss" scoped>
.button-container {
  :deep(.button-main) {
    margin-left: auto;
    margin-right: auto;
    + .button-main {
      margin-top: 15px;
    }
  }
}

</style>
