<template>
  <Modal ref="modal" variant="small">
    <div class="accept-number-modal">
      <p>例）</p>
      <ul>
        <li>PO番号</li>
        <li>修理番号</li>
        <li>ELP受付番号</li>
        <li>管理番号</li>
        <li>受付番号</li>
      </ul>
      <ContentsText><SmallText>修理店舗によって管理する番号が異なります。</SmallText></ContentsText>
      <p class="text">ご不明な点はサポートセンターまでお問い合わせ下さい。</p>
      <p class="tel"><a href="tel:0120914554">0120-914-554</a></p>
      <p class="text">（年中無休／通話料無料 9：00～20：00）</p>
    </div>
  </Modal>
</template>

<script>
  import Modal from '@/components/modal/Modal'
  import ContentsText from '@/components/text/ContentsText'
  import SmallText from '@/components/text/SmallText'

  export default {
    name: 'accept-number-modal',
    components: {
      SmallText,
      ContentsText,
      Modal
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/styles";
  .accept-number-modal {
    font-size: 15px;
    line-height: 1.4;

    ul {
      padding-left: 20px;

      li {
        list-style: initial;
      }
    }

    .contents-text {
      + .text {
        margin-top: 20px;
      }
    }

    .text {
      font-size: 12px;
    }

    .tel {
      margin-top: 20px;

      @include mq-up(sm) {
        pointer-events: none;
      }

      a {
        color: $orange-01;
        font-weight: bold;
        font-size: 20px;
        text-decoration: none;
      }
    }
  }
</style>
