<template>
    <div class="integrated-wrap">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'integrated-wrap',
    props: {
      isApplicableDevice: Boolean
    }
  }
  </script>
  
  <style lang="scss" scoped>  
  .integrated-wrap {
    margin-bottom: 35px;
  }
  </style>
  