<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>&nbsp;</SectionHeading>
    <ContentsContainer>
      <template v-if="rescuePattern === 1">
        <ContentsText>修理補償の申請を進める場合はこちら</ContentsText>
        <ButtonContainer>
          <ButtonMain variant="primary" @click="next">修理補償申請画面</ButtonMain>
        </ButtonContainer>
      </template>
      <template v-else-if="rescuePattern === 2">
        <ContentsText>お客さまのご利用端末は修理代金サポート（保険）の補償対象です。<br>保険金請求を進める場合はこちら</ContentsText>
        <ButtonContainer>
          <ButtonMain variant="primary" href="https://pass.auone.jp/anshin/service/hoken/">保険金請求画面</ButtonMain>
        </ButtonContainer>
      </template>
      <template v-else-if="rescuePattern === 3">
        <ContentsText>お客さまのご利用端末は修理代金サポート（保険）の端末情報事前登録が必要です。<br>端末情報事前登録がお済みの場合には以下より保険金請求をしてください。</ContentsText>
        <ButtonContainer>
          <ButtonMain variant="next" :to="{name: 'rep030', query: {p: this.$route.query.p}}">端末情報一覧ページへ</ButtonMain>
        </ButtonContainer>
      </template>
      <template v-else-if="rescuePattern === 4">
        <ContentsText>同じauID内に事前登録の情報があります。このまま登録しますか？<br> （すでに事前登録済みの端末が補償期間内の場合、補償期間が終了します）</ContentsText>
        <ButtonContainer>
          <ButtonMain variant="next" @click="register">登録する</ButtonMain>
          <ButtonMain :to="{name: 'rep010', params: {id: this.$route.query.p}}">登録しない(メニュー画面に戻る)</ButtonMain>
        </ButtonContainer>
      </template>
      <template v-else-if="rescuePattern === 5">
        <ContentsText>リユーススマートフォン「au Certified」で申請する場合は下記にお問い合わせください</ContentsText>
        <ButtonContainer>
          <ButtonMain :to="{name: 'rep010', params: {id: this.$route.query.p}}">メニューに戻る</ButtonMain>
        </ButtonContainer>
      </template>
      <template v-else-if="rescuePattern === 6">
        <ContentsText>お客様は端末事前登録の対象ではありません。<br>ご希望の申請内容をご選択ください。</ContentsText>
        <ButtonContainer>
          <ButtonMain variant="next" @click="support('2')">修理代金サポートを申請する</ButtonMain>
          <ButtonMain variant="next" @click="support('1')">紛失負担金サポートを申請する</ButtonMain>
        </ButtonContainer>
      </template>
      <CommonSupport/>
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading" />
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsText from '@/components/text/ContentsText'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'

import {postClaimPatternApi} from '@/utils/ApiHelper'
import {$_resultError, $_isError} from '@/utils/Error'

export default {
  name: "rep190",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    ContentsContainer,
    SectionHeading,
    ContentsText,
    ButtonContainer,
    ButtonMain
  },
  data () {
    return {
      isLoading: false,
      form: this.$store.getters.getClaimPattern
    }
  },
  computed: {
    //rescuePattern
    // 1：保険 → 修理/紛失、修理、スマパス2年限定
    // 2：修理 → 保険（免責なし）
    // 3：修理、保険 → 保険事前申請
    // 4：事前申請 → auID内に事前登録の情報があった場合、アラートを表示 → 事前申請
    rescuePattern() {
      return this.$store.getters.getRescuePattern
    }
  },
  methods: {
    next () {
      if (this.isLoading) return false
      this.isLoading = true

      const repairReferrerInfo = '2'
      let claimPattern = {...this.form, referrerInfo: Number(repairReferrerInfo)}
      postClaimPatternApi(claimPattern)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if (!$_isError(this.resultError, this.$route)) {
          if ('messages' in result) {
            this.$store.dispatch('setErrorMessage', result.messages[0].message)
            this.$router.push({name: result.messages[0].pageId, query: {'p': repairReferrerInfo}})
          } else if ('rescuePattern' in result) {
            this.$store.dispatch('setRescuePattern', result.rescuePattern)
            this.$store.dispatch('setCarrierType', result.carrierType)
            this.$router.push({name: 'rep190', query: {'p': repairReferrerInfo}})
          } else {
            let claimInit = {
              tel: this.form.tel,
              deviceId: this.form.deviceId,
              isUpdate: false,
              pageId: result.pageId,
              isAnotherTel: this.form.isAnotherTel
            }
            this.$store.dispatch('setClaimInit', claimInit)
            this.$store.dispatch('setCarrierType', result.carrierType)
            this.$router.push({name: result.pageId, query: {'p': repairReferrerInfo}})
          }
        }
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': repairReferrerInfo}})
      })
    },
    register() {
      if (this.isLoading) return;
      this.isLoading = true

      const claimPattern = { ...this.form, ignoreRescuePattern: true}
      const referrerInfo = claimPattern.referrerInfo.toString()
      postClaimPatternApi(claimPattern)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          const error = $_isError(this.resultError, this.$route)

          // エラーの場合
          if (error) {
            this.isLoading = false
            return;
          }

          // エラーメッセージが存在する場合
          if (result.messages) {
            this.$store.dispatch('setErrorMessage', result.messages[0].message)
            this.$router.push({name: result.messages[0].pageId, query: {'p': referrerInfo}})
            this.isLoading = false
            return;
          }

          /**
           * rescuePatternが存在する場合
           * 尚、ここではrescuePatternに 4 が含まれることは無い
           */
          if (result.rescuePattern) {
            this.$store.dispatch('setRescuePattern', result.rescuePattern)
            this.$store.dispatch('setCarrierType', result.carrierType)
            this.$router.push({name: 'rep190', query: {'p': referrerInfo}})
            this.isLoading = false
            return;
          }

          // 正常パターン
          const claimInit = {
            tel: claimPattern.tel,
            isUpdate: false,
            pageId: result.pageId,
            isAnotherTel: claimPattern.isAnotherTel
          }
          this.$store.dispatch('setClaimInit', claimInit)
          this.$store.dispatch('setCarrierType', result.carrierType)
          this.$router.push({name: result.pageId, query: {'p': referrerInfo}})
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': referrerInfo}})
          this.isLoading = false
        })
    },
    support(repairReferrerInfo) {
      if (this.isLoading) return;
      this.isLoading = true
      const claimPattern = { ...this.form, referrerInfo: Number(repairReferrerInfo)}
      const referrerInfo = claimPattern.referrerInfo.toString()
      postClaimPatternApi(claimPattern)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          const error = $_isError(this.resultError, this.$route)

          // エラーの場合
          if (error) {
            this.isLoading = false
            return;
          }

          // エラーメッセージが存在する場合
          if (result.messages) {
            this.$store.dispatch('setErrorMessage', result.messages[0].message)
            this.$router.push({name: result.messages[0].pageId, query: {'p': referrerInfo}})
            this.isLoading = false
            return;
          }

          // 正常パターン
          const claimInit = {
            tel: claimPattern.tel,
            isUpdate: false,
            pageId: result.pageId,
            isAnotherTel: claimPattern.isAnotherTel
          }
          this.$store.dispatch('setClaimInit', claimInit)
          this.$store.dispatch('setCarrierType', result.carrierType)
          this.$router.push({name: result.pageId, query: {'p': referrerInfo}})
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': referrerInfo}})
          this.isLoading = false
        })
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
