<template>
  <form @submit="() => false" class="form-file">
    <input
      type="file"
      accept="image/jpeg,application/pdf,image/png,image/bmp,image/heic"
      @change="updateValue"
      :name="name"
      :disabled=disabled
    >
  </form>
</template>

<script>

export default {
  name: 'form-file',
  props: {
    name: { type: String, require: true },
    disabled: { type: Boolean, require: false, 'default':false }
  },
  emits: ['change'],
  methods: {
    updateValue: function(e) {
      const files = e.target.files || e.dataTransfer.files
      this.$emit('change', files, e.target, this.$el);
    }
  }
}
</script>

<style lang="scss" scoped>
.form-file {
  display: block;
  font-size: 15px;
  line-height: 1.4;
  max-width: 100%;
  overflow: hidden;
  + .form-file {
    margin-top: 10px;
  }
}
</style>
