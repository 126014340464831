<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>管理者用設定画面</SectionHeading>
    <ContentsContainer>
      <Tab :tabs="tabs" :current-tab="currentTab" @click="changeTab"></Tab>
      <!-- vtkt設定 -->
      <template v-if="currentTab === 'vtkt'">
        <ItemContainer>
          <HeadingLabel>VTKT</HeadingLabel>
          <ContentsText>
            <p>現在設定されているVTKT: {{ currentVtkt }}</p>
          </ContentsText>
          <FormInput
              v-model="vtkt"
              placeholder="vtkt設定"
              name="currentVtkt"
              type="text"
          ></FormInput>
          <ButtonMain @click="copyCookie()">cookiesにvtktを設定する</ButtonMain>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>メニュー画面のURL</HeadingLabel>
        </ItemContainer>
        <ItemContainer>
          <ContentsText>
            <RouterLink :to="{ name:'rep010', params: {id: 1}}" target="_blank">rep010 メニュー /1 (紛失)</RouterLink>
          </ContentsText>
          <ContentsText>
            <RouterLink :to="{ name:'rep010', params: {id: 2}}" target="_blank">rep010 メニュー /2 (修理)</RouterLink>
          </ContentsText>
          <ContentsText>
            <RouterLink :to="{ name:'rep010', params: {id: 3}}" target="_blank">rep010 メニュー /3 (保険)</RouterLink>
          </ContentsText>
          <ContentsText>
            <RouterLink :to="{ name:'rep010', params: {id: 4}}" target="_blank">rep010 メニュー /4 (事前申請)</RouterLink>
          </ContentsText>
        </ItemContainer>
      </template>
      <!-- vtkt設定 -->

      <!-- 管理画面スタブ(本申請) -->
      <template v-if="currentTab === 'claim'">
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>

        <ItemContainer>
          <HeadingLabel>申請番号</HeadingLabel>
          <FormInput
              v-model="claimForm.appNumber"
              name="appNumber"
              type="text"
              :error="'appNumber' in errorMessage"
              placeholder=""
          ></FormInput>
          <ErrorMessage v-if="'appNumber' in errorMessage">{{ errorMessage.appNumber }}</ErrorMessage>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>申請ステータス</HeadingLabel>
          <FormSelect
              v-model="claimForm.statusCd"
              name="statusCd"
              :options="statusCdOption"
              :error="'statusCd' in errorMessage"
          ></FormSelect>
          <FormSelect
              v-if="showReasonCd"
              v-model="claimForm.reasonCd"
              name="reasonCd"
              :options="reasonCdOption"
              :error="'reasonCd' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'statusCd' in errorMessage">{{ errorMessage.statusCd }}</ErrorMessage>
          <ErrorMessage v-if="'reasonCd' in errorMessage">{{ errorMessage.reasonCd }}</ErrorMessage>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>補償タイプ</HeadingLabel>
          <FormSelect
              v-model="claimForm.serviceType"
              name="serviceType"
              :options="serviceTypeOption"
              :error="'serviceType' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'serviceType' in errorMessage">{{ errorMessage.serviceType }}</ErrorMessage>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="claimUpdate">更新</ButtonMain>
        </ButtonContainer>
      </template>
      <!-- 管理画面スタブ(本申請) -->

      <!-- 管理画面スタブ(事前申請) -->
      <template v-if="currentTab === 'entry'">
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>
        <ItemContainer>
          <HeadingLabel>事前申請番号</HeadingLabel>
          <FormInput
              v-model="entryForm.advanceEntryNumber"
              name="advanceEntryNumber"
              type="text"
              :error="'advanceEntryNumber' in errorMessage"
              placeholder=""
          ></FormInput>
          <ErrorMessage v-if="'advanceEntryNumber' in errorMessage">{{ errorMessage.advanceEntryNumber }}
          </ErrorMessage>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>事前申請ステータス</HeadingLabel>
          <FormSelect
              v-model="entryForm.status"
              name="status"
              :options="statusOption"
              :error="'status' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'status' in errorMessage">{{ errorMessage.status }}</ErrorMessage>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>事前申請の申請日を30日過去に設定する</HeadingLabel>
          <FormRadio
              v-model="entryForm.disclaimerPeriodOption"
              name="disclaimerPeriodOption"
              :options="disclaimerPeriodOption"
          ></FormRadio>
          <ErrorMessage v-if="'serviceType' in errorMessage">{{ errorMessage.serviceType }}</ErrorMessage>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="entryUpdate">更新</ButtonMain>
        </ButtonContainer>
      </template>
      <!-- 管理画面スタブ(事前申請) -->

    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import ItemContainer from '@/components/container/ItemContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsText from '@/components/text/ContentsText'
import FormInput from '@/components/form/FormInput'
import ButtonMain from '@/components/button/ButtonMain'
import HeadingLabel from '@/components/heading/HeadingLabel'
import CommonLoading from '@/components/common/CommonLoading'
import Tab from '@/components/tab/Tab'
import ErrorMessage from '@/components/error/ErrorMessage'
import FormSelect from '@/components/select/FormSelect'
import ButtonContainer from '@/components/button/ButtonContainer'
import FormRadio from '@/components/select/FormRadio'
import ErrorBox from '@/components/error/ErrorBox'
import {$_resultError, $_validationErrors, $_validationErrorMessage, $_errorMessages, $_isError} from '@/utils/Error'

export default {
  name: "pagelist",
  components: {
    ErrorBox,
    FormRadio,
    ButtonContainer,
    FormSelect,
    ErrorMessage,
    Tab,
    CommonLoading,
    HeadingLabel,
    CommonHeader,
    CommonFooter,
    MainContainer,
    ContentsContainer,
    ItemContainer,
    SectionHeading,
    ContentsText,
    FormInput,
    ButtonMain,
  },
  data () {
    return {
      currentTab: 'vtkt',
      vtkt: '',
      currentVtkt: '',
      isLoading: false,
      claimForm: {
        "appNumber": "",
        "statusCd": 110,
        "serviceType": 0,
        "reasonCd": 110
      },
      entryForm: {
        "advanceEntryNumber": "",
        "status": 1,
        "disclaimerPeriodOption": true
      },
      resultError: []
    }
  },
  computed: {
    tabs () {
      return [
        {key: 'vtkt', label: 'VTKT設定'},
        {key: 'claim', label: '管理画面スタブ(本申請)'},
        {key: 'entry', label: '管理画面スタブ(事前申請)'},
      ]
    },
    errorMessage () {
      return $_validationErrorMessage(this.resultError)
    },
    errorMessages () {
      return $_errorMessages(this.resultError)
    },
    isError() {
      return $_validationErrors(this.resultError).length > 0
    },
    statusCdOption () {
      return [
        {label: '審査待ち', value: 110},
        {label: '不備', value: 250},
        {label: '口座不備', value: 380},
        {label: '不合格', value: 130},
        {label: 'ポイント付与済み', value: 350},
        {label: '振込済み', value: 400}
      ]
    },
    serviceTypeOption () {
      return [
        {label: '変更なし', value: 0},
        {label: '役務スマプレ', value: 1},
        {label: '役務スマパス', value: 2},
        {label: '2年限定', value: 3},
        {label: 'au保険免責なし', value: 4},
        {label: 'au保険免責あり', value: 5},
        {label: 'UQ保険', value: 6},
        {label: 'その他保険', value: 7},
        {label: '紛失', value: 8},
      ]
    },
    statusOption () {
      return [
        {label: '合格', value: 1},
        {label: '不備', value: 2},
        {label: '不合格', value: 3},
        {label: '申請不要', value: 5},
      ]
    },
    disclaimerPeriodOption () {
      return [
        {label: 'する', value: true},
        {label: 'しない', value: false},
      ]
    },
    reasonCdOption () {
      return [
        {label: '口座不備', value: 110},
        {label: '口座情報不足', value: 111},
      ]
    },
    showReasonCd() {
      return this.claimForm.statusCd === 380
    }
  },
  mounted () {
    if (window.$cookies.isKey('VTKT')) {
      this.currentVtkt = window.$cookies.get('VTKT')
    } else {
      this.currentVtkt = "未設定"
    }
  },
  methods: {
    copyCookie () {
      this.isLoading = true
      document.cookie = 'VTKT=' + this.vtkt + '; path=/'
      this.currentVtkt = this.vtkt
      this.isLoading = false
    },
    changeTab (value) {
      this.resultError = []
      this.currentTab = value
    },
    claimUpdate () {
      if (this.isLoading) return false
      this.isLoading = true
      this.resultError = []

      let sendReasonCd = null
      if (this.showReasonCd)  sendReasonCd =  this.claimForm.reasonCd
      let form = {...this.claimForm, "reasonCd": sendReasonCd}

      this.adminStubApi('/admin_stub/claim', form)
          .then(res => {
            const result = res.data.result
            this.resultError = $_resultError(result)
            if (!$_isError(this.resultError)) {
              alert('更新に成功しました')
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
            alert('更新に失敗しました。もう一度最初からやりなおしてください。')
          })
    },
    entryUpdate () {
      if (this.isLoading) return false
      this.isLoading = true
      this.resultError =[]

      this.adminStubApi('/admin_stub/entry', this.entryForm)
          .then(res => {
            const result = res.data.result
            this.resultError = $_resultError(result)
            if (!$_isError(this.resultError)) {
              alert('更新に成功しました')
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
            alert('更新に失敗しました。もう一度最初からやりなおしてください。')
          })

    },
    adminStubApi (apiPath, requestData) {
      return new Promise(function (resolve, reject) {
        const axios = require('axios');

        const headers = {
          'accept': 'application/json',
          'Content-Type': 'application/json',

        }
        const url = `https://ooggxwh90m.execute-api.ap-northeast-1.amazonaws.com/Prod${apiPath}`
        const token = 'EULmUNMhjpEp6Lr2rpo0LqHG18swIfLdkjxGO3SPt2jYwsjvAup2MvavQHQShGlY8f0TL7DKXdTxuV0OoYwbPylixaA4MDWUp7LvSTS4ny8oFpuFiTD6uavO4kSuJVb54FFFCumd3JvVRkNGtFubSXQV1TGuSvwRlYvdvBNn7nknjNfqKONqXsN6HKV3xxbiI4Ron567BvsoUJOwGpyRhwKTTXdT8gL4QYsP03JKJQCx73ES31mlpOqYXVT4cJLB'
        const data = {token: token, ...requestData}
        axios.post(url, data, {headers: headers, timeout: 30000})
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.contents-container {
  .tab {
    margin-bottom: 30px;
  }
}
</style>
