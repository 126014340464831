import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'

import Components from '@/pages/Components.vue'
import Pagelist from '@/pages/Pagelist.vue'

import REP010 from '@/pages/rep010.vue'
import REP021 from '@/pages/rep021.vue'
import REP022 from '@/pages/rep022.vue'
import REP023 from '@/pages/rep023.vue'
import REP024 from '@/pages/rep024.vue'
import REP030 from '@/pages/rep030.vue'
import REP040 from '@/pages/rep040.vue'
import REP050 from '@/pages/rep050.vue'
import REP100 from '@/pages/rep100.vue'
import REP110 from '@/pages/rep110.vue'
import REP120 from '@/pages/rep120.vue'
import REP130 from '@/pages/rep130.vue'
import REP140 from '@/pages/rep140.vue'
import REP150 from '@/pages/rep150.vue'
import REP160 from '@/pages/rep160.vue'
import REP170 from '@/pages/rep170.vue'
import REP190 from '@/pages/rep190.vue'
import REP200 from '@/pages/rep200.vue'
import REP300 from '@/pages/rep300.vue'
import REP310 from '@/pages/rep310.vue'
import REP320 from '@/pages/rep320.vue'
import REP330 from '@/pages/rep330.vue'
import REP340 from '@/pages/rep340.vue'
import REP350 from '@/pages/rep350.vue'

const enterDevOnly = (to, from, next) => {
  if (process.env.VUE_APP_NODE_ENV === 'development' || process.env.VUE_APP_NODE_ENV === 'staging') {
    next()
  } else {
    next({ name: 'rep310' })
  }
}

const routes = [
  {
    path: '/components',
    name: 'components',
    component: Components,
    beforeEnter: enterDevOnly
  },
  {
    path: '/pagelist',
    name: 'pagelist',
    component: Pagelist,
    beforeEnter: enterDevOnly
  },
  {
    path: '/:id([1|2|3|4])',
    name: 'rep010',
    component: REP010
  },
  {
    path: '/claim_device_select',
    name: 'rep021',
    component: REP021
  },
  {
    path: '/claim_tel_select',
    name: 'rep022',
    component: REP022
  },
  {
    path: '/entry_device_select',
    name: 'rep023',
    component: REP023
  },
  {
    path: '/entry_tel_select',
    name: 'rep024',
    component: REP024
  },
  {
    path: '/entry_list',
    name: 'rep030',
    component: REP030
  },
  {
    path: '/claim_list',
    name: 'rep040',
    component: REP040
  },
  {
   path: '/right_count',
   name: 'rep050',
   component: REP050
  },
  {
    path: '/lost',
    name: 'rep100',
    component: REP100
  },
  {
    path: '/repair',
    name: 'rep110',
    component: REP110
  },
  {
    path: '/sma2',
    name: 'rep120',
    component: REP120
  },
  {
    path: '/insurance',
    name: 'rep130',
    component: REP130
  },
  {
    path: '/entry',
    name: 'rep140',
    component: REP140
  },
  {
   path: '/account',
   name: 'rep150',
   component: REP150
  },
  {
    path: '/complete',
    name: 'rep160',
    component: REP160
  },
  {
    path: '/complete_entry',
    name: 'rep170',
    component: REP170
  },
  {
    path: '/advice',
    name: 'rep190',
    component: REP190
  },
  {
    path: '/support',
    name: 'rep200',
    component: REP200
  },
  {
    path: '/error',
    name: 'rep300',
    component: REP300,
  },
  {
    path: '/404',
    name: 'rep310',
    component: REP310
  },
  {
    path: '/500',
    name: 'rep320',
    component: REP320
  },
  {
    path: '/408',
    name: 'rep330',
    component: REP330
  },
  {
    path: '/504',
    name: 'rep340',
    component: REP340
  },
  {
    path: '/503',
    name: 'rep350',
    component: REP350
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  }
]

const scrollBehavior = () => {
  return {x: 0, y: 0}
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
});

const DIRECT_TRANSITION_PAGES = ['rep010', 'rep310', 'rep320', 'rep330', 'rep340', 'rep350']

router.beforeEach((to, from, next) => {
  setHoken(to)
  let p = to.query.p
  if (p && !["1", "2", "3", "4"].includes(p)) {
    next({ name: 'rep310' })
  }
  // 開発用 リロードでrep010に遷移しないように
  if (process.env.VUE_APP_NODE_ENV === 'staging') {
    DIRECT_TRANSITION_PAGES.push('pagelist')
  }
  if (process.env.VUE_APP_NODE_ENV === 'development') {
    return next()
  }
  // 開発用 リロードでrep010に遷移しないように
  if (DIRECT_TRANSITION_PAGES.includes(to.name)) {
    return next()
  }
  from.name === undefined ? next({name: 'rep010', params: {id: p}}) : next()
})

function setHoken(to) {
  const HOKEN_PAGES = ['rep030', 'rep140']
  const UNCHANGED_HOKEN_PAGES = ['rep040', 'rep050', 'rep310', 'rep320', 'rep330', 'rep340', 'rep350']
  const HOKEN_PARAMS = ['3', '4']
  const UNCHANGED_HOKEN_PARAMS = ['1', '2']
  let param = to.name === 'rep010' ? to.params.id : to.query.p

  if (UNCHANGED_HOKEN_PAGES.includes(to.name)) {
    store.dispatch('setIsHoken', false)
  } else if (HOKEN_PAGES.includes(to.name)) {
    store.dispatch('setIsHoken', true)
  } else if (UNCHANGED_HOKEN_PARAMS.includes(param)) {
    store.dispatch('setIsHoken', false)
  } else if (HOKEN_PARAMS.includes(param)) {
    store.dispatch('setIsHoken', true)
  } else {
    store.dispatch('setIsHoken', false)
  }
}

export default router;
