<template>
  <div>
    <CommonHeader/>
    <ContentsContainer>
      <h2 class="title">Heading</h2>
      <div class="component">
        <label class="label">Label</label>
        <div class="container">
          <HeadingLabel>ラベルテキスト</HeadingLabel>
        </div>
      </div>
      <div class="component">
        <label class="label">SectionHeading</label>
        <div class="container">
          <SectionHeading>見出しタイトル</SectionHeading>
        </div>
      </div>

      <h2 class="title">Form</h2>
      <div class="component">
        <label class="label">TextField</label>
        <div class="container">
          <FormInput
            v-model="form.textfield"
            placeholder="プレースホルダー"
            name="textfield-component"
            type="text"
          ></FormInput>
        </div>
      </div>
      <div class="component">
        <label class="label">TextField(disabled)</label>
        <div class="container">
          <FormInput
            v-model="form.textfield"
            placeholder="プレースホルダー"
            name="textfield-component"
            type="text"
            :disabled=true
          ></FormInput>
        </div>
      </div>
       <div class="component">
        <label class="label">TextField(error)</label>
        <div class="container">
          <FormInput
            v-model="form.textfield"
            placeholder="プレースホルダー"
            name="textfield-component"
            type="text"
            :error=true
          ></FormInput>
        </div>
      </div>
      <div class="component">
        <label class="label">DatePicker</label>
        <div class="container">
          <FormDate
            v-model="form.date"
            name="date-component"
            @input="updateValue"
          ></FormDate>
        </div>
      </div>
      <div class="component">
        <label class="label">DatePicker(disabled)</label>
        <div class="container">
          <FormDate
            v-model="form.date"
            name="date-component-disabled"
            :disabled=true
          ></FormDate>
        </div>
      </div>
      <div class="component">
        <label class="label">DatePicker(error)</label>
        <div class="container">
          <FormDate
            v-model="form.date"
            name="date-component-error"
            :error=true
          ></FormDate>
        </div>
      </div>
      <div class="component">
        <label class="label">TextArea</label>
        <div class="container">
          <FormTextarea
            v-model="form.textArea"
            placeholder="テキストエリア"
            name="date-component"
          ></FormTextarea>
        </div>
      </div>
      <div class="component">
        <label class="label">TextArea(disabled)</label>
        <div class="container">
          <FormTextarea
            v-model="form.textArea"
            placeholder="テキストエリア"
            name="date-component-disabled"
            :disabled=true
          ></FormTextarea>
        </div>
      </div>
      <div class="component">
        <label class="label">TextArea(error)</label>
        <div class="container">
          <FormTextarea
            v-model="form.textArea"
            placeholder="テキストエリア"
            name="date-component-error"
            :error=true
          ></FormTextarea>
        </div>
      </div>
      <div class="component">
        <label class="label">Pulldown</label>
        <div class="container">
          <FormSelect
            v-model="form.select"
            name="select-component"
            :options="selectOptions"
          ></FormSelect>
        </div>
      </div>
      <div class="component">
        <label class="label">Pulldown(disabled)</label>
        <div class="container">
          <FormSelect
            v-model="form.select"
            name="select-component-disabled"
            :options="selectOptions"
            :disabled=true
          ></FormSelect>
        </div>
      </div>
      <div class="component">
        <label class="label">Pulldown(error)</label>
        <div class="container">
          <FormSelect
            v-model="form.select"
            name="select-component-error"
            :options="selectOptions"
            :error=true
          ></FormSelect>
        </div>
      </div>
      <div class="component">
        <label class="label">FileUploader</label>
        <div class="container">
          <FormFile
            name="file-component"
          ></FormFile>
        </div>
      </div>

      <h2 class="title">Select</h2>
      <div class="component">
        <label class="label">CheckBoxIcon</label>
        <div class="container">
          <input
            type="checkbox"
            name="checkbox-icon-component"
            v-model="form.checkboxIcon"
          >
        </div>
      </div>
      <div class="component">
        <label class="label">CheckBox</label>
        <div class="container">
          <FormCheckbox
            name="checkbox-component"
            v-model="form.checkbox"
            :options="checkboxOptions"
                        @input="updateValue"
          ></FormCheckbox>
        </div>
      </div>
      <div class="component">
        <label class="label">RadioIcon</label>
        <div class="container">
          <input
            type="radio"
            name="radio-icon-component"
            v-model="form.radioIcon"
          />
        </div>
      </div>
      <div class="component">
        <label class="label">Radio</label>
        <div class="container">
          <FormRadio
            name="radio-component"
            v-model="form.radio"
            :options="checkboxOptions"
            direction="row"
          ></FormRadio>
        </div>
      </div>

      <h2 class="title">Error</h2>
      <div class="component">
        <label class="label">ErrorMessage</label>
        <div class="container">
          <ErrorMessage>※エラーメッセージが入ります。</ErrorMessage>
        </div>
      </div>
      <div class="component">
        <label class="label">ErrorBox</label>
        <div class="container">
          <ErrorBox>入力内容に問題があるため申請を進めることができません。<br>各入力欄のメッセージをご確認ください。</ErrorBox>
        </div>
      </div>

      <h2 class="title">Button</h2>
      <div class="component">
        <label class="label">PrimaryButton</label>
        <div class="container">
          <ButtonMain variant="primary">次へ</ButtonMain>
        </div>
      </div>
      <div class="component">
        <label class="label">NextButton</label>
        <div class="container">
          <ButtonMain variant="next">次へ</ButtonMain>
        </div>
      </div>
      <div class="component">
        <label class="label">NormalButton</label>
        <div class="container">
          <ButtonMain>次へ</ButtonMain>
        </div>
      </div>

      <h2 class="title">Container</h2>
      <div class="component">
        <label class="label">Mask</label>
        <div class="container">
          <mask-container class="box"></mask-container>
        </div>
      </div>
      <div class="component">
        <label class="label">Orange</label>
        <div class="container">
          <div class="orange-container box"></div>
        </div>
      </div>
      <div class="component">
        <label class="label">Error</label>
        <div class="container">
          <div class="error-container box"></div>
        </div>
      </div>

      <h2 class="title">Modal</h2>
      <div class="component">
        <label class="label">Modalios</label>
        <div class="container">
          <ButtonMain @click="close">移動</ButtonMain>
        </div>
      </div>

    </ContentsContainer>
    <CommonFooter/>
  </div>
</template>

<script>
import ContentsContainer from '@/components/container/ContentsContainer'
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import HeadingLabel from '@/components/heading/HeadingLabel'
import SectionHeading from '@/components/heading/SectionHeading'
import FormInput from '@/components/form/FormInput'
import FormDate from '@/components/form/FormDate'
import FormTextarea from '@/components/form/FormTextarea'
import FormFile from '@/components/form/FormFile'
import FormSelect from '@/components/select/FormSelect'
import FormCheckbox from '@/components/select/FormCheckbox'
import FormRadio from '@/components/select/FormRadio'
import ErrorMessage from '@/components/error/ErrorMessage'
import ErrorBox from '@/components/error/ErrorBox'
import ButtonMain from '@/components/button/ButtonMain'

export default {
  name: 'components',
  data() {
    return {
      form: {
        textfield: '',
        date: undefined,
        textArea: '',
        select: '',
        checkboxIcon: false,
        checkbox: [],
        radioIcon: '',
        radio: '1',
      },
      selectOptions: [{label: '選択してください', value: ''}, {label: '修理補償', value: "1"}, {label: '紛失補償', value: "2"}],
      checkboxOptions: [{label: '2019年9月22日', value: '1'}, {label: '2019年9月23日', value: "2"}, {label: '2019年9月24日', value: "3"}],
      checkboxOption2: [{label: '2019年9月22日', value: '1'}, {label: '2019年9月23日', value: "2"}, {label: '2019年9月24日', value: "3"}],
      radioOption: [{label: "選択1", value: "1"}, {label: "選択2", value: "2"}]
    };
  },
  components: {
    CommonHeader,
    CommonFooter,
    HeadingLabel,
    SectionHeading,
    FormSelect,
    FormInput,
    FormDate,
    FormTextarea,
    FormFile,
    FormCheckbox,
    FormRadio,
    ErrorMessage,
    ErrorBox,
    ButtonMain,
    ContentsContainer
  },
  methods: {
    updateValue: function() {
      // console.log(this.form)
    },
    close: function() {
      confirm('このサイトから移動しますか？\nその場合、入力された内容が失われます。')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.contents-container {
  .title {
    font-size: 28px;
    padding-bottom: 13px;
    margin-top: 90px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-line;
  }
}

.component {
  display: flex;
  margin-bottom: 40px;
  @include mq-down(md) {
    flex-direction: column;
  }
  @include mq-up(md) {
    align-items: center;
  }
  > .label {
    display: block;
    font-size: 20px;
    @include mq-down(md) {
      margin-bottom: 20px;
    }
    @include mq-up(md) {
      width: 228px;
    }
  }
  > .container {
    width: 100%;
    @include mq-up(md) {
      width: calc(100% - 228px);
    }
  }
}

.orange-container {
  background-color: $orange-bg;
}
.mask-container {
  background-color: rgba($black-01, 0.7);
}
.error-container {
  background-color: $red-bg;
}

.box {
  width: 160px;
  height: 80px;
}
</style>
