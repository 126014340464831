<template>
  <div class="tab">
    <ul>
      <li v-for="tab in tabs" :key="tab.key" :class="{'is-active': isActiveTab(tab.key)}" @click="click(tab.key)">{{tab.label}}</li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'tab',
  props: {
    tabs: { type: Array, require: true },
    currentTab: { type: String, default: ''}
  },
  emits: ['click'],
  methods: {
    click: function (e) {
      this.$emit('click', e)
    },
    isActiveTab(tab) {
      return this.currentTab === tab
    }
  }
}

</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.tab {
  //max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  > ul {
    display: inline-flex;
    max-width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display:none;
    }

    > li {
      position: relative;
      box-sizing: content-box;
      //background-color: $gray-line;
      background-color: $white-01;
      max-width: fit-content;
      min-width: 1em;
      height: 32px;
      margin-top: 4px;
      border-radius: 6px 6px 0 0;
      cursor: pointer;
      overflow: hidden;
      //color: $gray-thin;
      color: $gray-text;
      line-height: 32px;
      white-space: nowrap;
      padding: 0 14px;
      border: 1px solid $gray-line;

      &:nth-child(n+2) {
        margin-left: 2px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background-color: $gray-thin;
      }

      &.is-active {
        flex-shrink: 0;
        //background-color: $white-01;
        //background-color: $green;
        background-color: $orange-01;
        height: 36px;
        margin-top: 0;
        cursor: default;
        //color: $black-02;
        color: $white-01;
        line-height: 36px;

        &:after {
          background-color: $white-01;
        }
      }
    }
  }
}

</style>
