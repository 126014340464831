<template>
  <label class="heading-label"><img src="@/assets/images/icon_title_block.png" alt=""><slot></slot></label>
</template>

<script>
export default {
  name: 'heading-label'
}
</script>

<style lang="scss" scoped>
.heading-label{
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1;
  img {
    width: 7px;
    margin-right: 5px;
  }
}
</style>