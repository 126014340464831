<template>
  <component
		:is="to ? 'router-link' : 'a'"
		:class="disabled ? 'button-box disabled' : 'button-box'"
		:href="href"
		:to="disabled ? '' : to"
		@click="click"
		:target="target"
		:disabled="disabled"
  >
    <div class="container">
      <p class="header"><slot name="header"></slot></p>
      <img src="@/assets/images/icon_arrow_gray_right.svg" class="arrow">
    </div>
    <p v-if="this.$slots.text" class="text"><slot name="text"></slot></p>
  </component>
</template>

<script>
export default {
  name: 'button-box',
  props: {
    to: { type: Object, default: null },
    href: { type: String, default: '' },
    target: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  emits: ['click'],
  methods: {
    click: function (e) {
      if (this.disabled) {
        e.preventDefault()
      } else {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .button-box {
    display: block;
    width: 100%;
    padding: 20px;
    background: $white-01;
    border: 1px solid $gray-line;
    box-shadow: 0 2px 0 $gray-02;
    cursor: pointer;
    text-decoration: none;

    &.disabled {
      background-color: $gray-bg;
      cursor: default;
      >.header {
        color: $gray-02
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .header {
        width: calc(100% - 32px);
        color: $black-02;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }

      .arrow {
        width: auto;
        height: 12px;
        margin-top: 6.5px;
      }
    }

    .text {
      margin-top: 5px;
      font-size: 12px;
      line-height: 17px;
      color: $gray-text-02;
    }

    + .button-box {
      margin-top: 12px;
    }
  }

  .heading-label + .button-box {
    margin-top: 12px;
  }
</style>
