<template>
  <fieldset class="form-radio">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="form-radio-label"
      :class="{['is-' + direction]: direction}"
    >
      <input
        type="radio"
        v-model="selected"
        :name="name"
        :value="option.value"
        class="radio"
      />{{ option.label }}
    </label>
  </fieldset>
</template>

<script>

export default {
  name: 'form-radio',
  props: {
    modelValue: {type: [String, Number], require: true},
    options: {type: Array, require: true},
    name: {type: String, require: true},
    direction: {type: String}
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get: function () {
        return this.modelValue
      },
      set: function (newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-radio {
  .form-radio-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.4;
    > .radio {
      margin-right: 10px;
    }
    + .form-radio-label:not(.is-row) {
      margin-left: 20px;
    }
    &.is-row {
      display: flex;
      + .form-radio-label {
        margin-top: 10px;
      }
    }
  }
}

</style>
