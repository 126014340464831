<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading ><template v-if="!isLoading">申請端末選択</template></SectionHeading>
    <ContentsContainer v-if="!isLoading">

      <ErrorBox v-if="isError">
        <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
      </ErrorBox>

      <ItemContainer>
        <HeadingLabel>申請するご利用端末を選択してください。</HeadingLabel>
        <ContentsText>
          <SmallText>同一auIDでの複数の事前登録は行えません。<br>登録機種を変更する場合のみ再度ご登録ください。</SmallText>
        </ContentsText>
        <Card v-for="(deviceInfo, index) in deviceInfoList" :key="index" :disabled="!deviceInfo.isApplicableDevice"
        >
          <CardContainer>
            <CardImg :src="deviceInfo.deviceImageUrl" />
            <CardList
              :list="[
                { label: '機種名', data: deviceInfo.deviceName },
                { label: '電話番号', data: deviceInfo.isAssociatedWithTel? deviceInfo.telForDisplay : 'au回線契約なし' },
                { label: '端末購入日', data: deviceInfo.devicePurchaseDate }
              ]" />
          </CardContainer>
          <ButtonContainer v-if=deviceInfo.isApplicableDevice>
            <ButtonMain variant="next"  @click="next(deviceInfo)">この端末を事前申請する</ButtonMain>
          </ButtonContainer>
          <CardNote v-else>この端末は事前申請が不要です。<a href="https://pass.auone.jp/anshin/#anshin-support">こちら</a>から修理代金サポートを申請してください。</CardNote>
        </Card>
      </ItemContainer>
      <ItemContainer>
        <HeadingLabel>申請する端末がみつかりませんか？</HeadingLabel>
        <ButtonBox :to="{name: 'rep024', query: {'p': this.$route.query.p}}">
          <template v-slot:header>一覧にない端末で事前申請する</template>
          <template v-slot:text>事前申請をご希望される端末が一覧に表示されていない場合こちらから、端末に紐づく電話番号を指定する形で事前申請を進める事が出来る場合がございます。</template>
        </ButtonBox>
      </ItemContainer>

      <ButtonContainer>
        <ButtonMain :to="{name: 'rep010', params: {id: this.$route.query.p}}">戻る</ButtonMain>
      </ButtonContainer>
      <ContentsText>
        <SmallText variant="non-asterisk"> 
          ※修理代金サポートでは、事前申請を希望される端末と紐づいたauIDでログインする必要がございます。<br>
          ※ご契約もしくは機種変更、本体交換当日の場合には表示される情報が最新でないことがあるため、翌日以降にご利用ください。<br>
        </SmallText>
      </ContentsText>
      <ContentsText>
        <SmallText variant="non-asterisk">
          事前申請を希望される端末が一覧に表示されない場合はauIDをご確認の上、再度ログインをお願い致します。<br>
          再度ログインを頂いても、端末が一覧に表示されない場合は修理代金サポートセンターへお問い合わせください。
        </SmallText>
      </ContentsText>
      <CommonSupport/>
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
  import CommonHeader from '@/components/common/CommonHeader'
  import CommonFooter from '@/components/common/CommonFooter'
  import CommonSupport from '@/components/common/CommonSupport'
  import CommonLoading from "@/components/common/CommonLoading"
  import MainContainer from '@/components/container/MainContainer'
  import SectionHeading from '@/components/heading/SectionHeading'
  import ContentsContainer from '@/components/container/ContentsContainer'
  import ItemContainer from '@/components/container/ItemContainer'
  import HeadingLabel from '@/components/heading/HeadingLabel'
  import ContentsText from '@/components/text/ContentsText'
  import SmallText from '@/components/text/SmallText'
  import ErrorBox from '@/components/error/ErrorBox'
  import ButtonBox from '@/components/button/ButtonBox'
  import ButtonContainer from '@/components/button/ButtonContainer'
  import ButtonMain from '@/components/button/ButtonMain'
  import Card from '@/components/card/Card'
  import CardContainer from '@/components/card/CardContainer'
  import CardImg from '@/components/card/CardImg'
  import CardList from '@/components/card/CardList'
  import CardNote from '@/components/card/CardNote'

  import {postSelectDeviceListApi, postClaimPatternApi} from '@/utils/ApiHelper'
  import {$_resultError, $_validationErrors, $_errorMessages, $_isError} from '@/utils/Error'

  export default {
    name: "rep023",
    components: {
      CommonHeader,
      CommonFooter,
      CommonSupport,
      CommonLoading,
      MainContainer,
      SectionHeading,
      ContentsContainer,
      ItemContainer,
      HeadingLabel,
      ContentsText,
      SmallText,
      ErrorBox,
      ButtonBox,
      ButtonContainer,
      ButtonMain,
      Card,
      CardContainer,
      CardImg,
      CardList,
      CardNote
    },
    data () {
      return {
        isLoading: false,
        deviceInfoList: [],
        hasNoApplicableDevice: false,
        form: {
          tel: "",
          deviceId: "",
          isAnotherTel: false,
          referrerInfo: Number(this.$route.query.p)
        },
        resultError: []
      }
    },
    computed: {
      errors() {
        return $_validationErrors(this.resultError)
      },
      isError () {
        return this.errors.length > 0
      },
      errorMessage () {
        let errorMessage = {}
        for (const error of this.errors) {
          if (error.errorItem) {
            errorMessage[error.errorItem] = error.errorMessage
          }
        }
        return errorMessage
      },
      errorMessages () {
        return $_errorMessages(this.resultError)
      }
    },
    methods: {
      next (deviceInfo) {
        if (this.isLoading) return false
        this.isLoading = true
        this.resultError = []

        if(deviceInfo.isOpenKosapo) {
          this.form.deviceId = deviceInfo.deviceId
        } else {
          this.form.tel = deviceInfo.tel
        }
        postClaimPatternApi(this.form)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            if ('messages' in result) {
              this.$store.dispatch('setErrorMessage', result.messages[0].message)
              this.$router.push({name: result.messages[0].pageId, query: {'p': this.$route.query.p}})
            } else if ('rescuePattern' in result) {
              this.$store.dispatch('setRescuePattern', result.rescuePattern)
              this.$store.dispatch('setClaimPattern', this.form)
              this.$store.dispatch('setCarrierType', result.carrierType)
              this.$router.push({name: 'rep190', query: {'p': this.$route.query.p}})
            } else {
              let claimInit = {
                tel: this.form.tel,
                deviceId: this.form.deviceId,
                isUpdate: false,
                pageId: result.pageId,
                isAnotherTel: this.form.isAnotherTel
              }
              this.$store.dispatch('setClaimInit', claimInit)
              this.$store.dispatch('setCarrierType', result.carrierType)
              this.$router.push({name: result.pageId, query: {'p': this.$route.query.p}})
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
      }
    },
    mounted () {
      this.isLoading = true
      const includeOpenKosapo = false
      const isFromEntry = true
      postSelectDeviceListApi(includeOpenKosapo, isFromEntry)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.deviceInfoList = result.deviceInfoList
        this.hasNoApplicableDevice = result.hasNoApplicableDevice
        if(this.hasNoApplicableDevice) {
          this.$router.push({name: 'rep024', query: {'p': this.$route.query.p}})
        }
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
      })
    }
  }
</script>
<style lang="scss" scoped>
  .section-heading {
    min-height: 38px;
  }
 .item-container + .item-container {
    margin-top: 60px;
  }

  .contents-container .button-container + .contents-text {
    margin-top: 20px;
  }

</style>
