<template>
  <div
    :class="
      isApplicableDevice
        ? 'integrated-device-container'
        : 'integrated-device-container gray'
    "
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'integrated-device-container',
  props: {
    isApplicableDevice: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.integrated-device-container {
  border: 1px solid $link-text-02;
  margin-top: 20px;
  &.gray {
    border-color: $gray-text-02;
    background: $gray-bg;
  }
}
</style>
