<template>
  <div class="number-container"><slot></slot></div>
</template>

<script>

export default {
  name: 'orange-container'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.number-container {
	width: 100%;
  padding: 10px 15px 15px;
  background-color: $orange-bg;
  color: $orange-02;
  text-align: center;
  :deep(.label) {
    font-size: 12px;
    line-height: 16px;
  }
  :deep(.number) {
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
  }

}
</style>
