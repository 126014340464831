<template>
  <p class="error-message"><slot></slot></p>
</template>

<script>

export default {
  name: 'error-message'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.error-message {
  font-size: 12px;
  line-height: 14px;
  color: $red-text;
}
</style>
