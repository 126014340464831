<template>
  <label class="integrated-device-heading-label">
    <img src="@/assets/images/icon_title_block.svg" alt="" class="icon_title_block">
    <img src="@/assets/images/icon_title_block_gray.svg" alt="" class="icon_title_block_gray">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'integrated-device-heading-label',
};
</script>

<style lang="scss" scoped>
.integrated-device-heading-label {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1;
  img {
    width: 7px;
    margin-right: 5px;
    &.icon_title_block_gray{display: none;}
  }
}
.gray .integrated-device-heading-label{
    img{
      &.icon_title_block{display: none;}
      &.icon_title_block_gray{display: block;}
    }
}
</style>
