import 'es6-promise/auto'
import { createApp } from "vue";
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import VueCookies from 'vue-cookies'

import VueScrollTo from "vue-scrollto";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueCookies)
  .use(VueScrollTo)

app.config.productionTip = false;
app.config.errorHandler = (err, vm, info) => {
  err;vm;info;
  // console.log('err', err)
  // console.log('vm', vm)
  // console.log('info', info)
  router.push({ name: 'rep320' })
}
app.mount("#app");
