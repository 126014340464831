<template>
  <h1 class="section-heading"><span class="inner"><slot></slot></span></h1>
</template>

<script>
export default {
  name: 'section-heading'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.section-heading {
  background-color: $gray-02;
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  > .inner {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    @include mq-up(sm) {
      max-width: 940px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
