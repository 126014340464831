<template>
  <MainContainer>
    <CommonHeader/>
    <div v-show="isEdit">
      <SectionHeading>端末修理費用特約付動産総合保険<br/>端末情報事前登録</SectionHeading>
      <ContentsContainer>
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>

        <ItemContainer>
          <ContentsText>保険対象とする端末情報を事前に登録する必要がございます。下記項目を入力してください。</ContentsText>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>ご契約者名/被保険者名</HeadingLabel>
          <FormInput
              v-model="form.insuredName"
              name="insuredName"
              type="text"
              placeholder="英雄太郎"
              maxlength="60"
              :error="'insuredName' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'insuredName' in errorMessage">{{errorMessage.insuredName}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>保険対象とする端末</HeadingLabel>
          <FormSelect
              v-model="form.deviceKind"
              name="deviceKind"
              :options="mobileOption"
              :error="'deviceKind' in errorMessage"
              @input="selectDeviceKind"
          ></FormSelect>
          <FormSelect
              v-model="form.device"
              name="device"
              v-if="form.deviceKind === 1"
              :disabled="form.deviceKind !== 1"
              :options="modelTypeListOption"
              :error="'device' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'deviceKind' in errorMessage && !form.deviceKind">{{errorMessage.deviceKind}}</ErrorMessage>
          <ErrorMessage v-if="'device' in errorMessage && form.deviceKind">{{errorMessage.device}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>連絡先電話番号</HeadingLabel>
          <ContentsText>
            <SmallText>お電話またはSMSにてご連絡させて頂く場合がございます。</SmallText>
            <SmallText>－（ハイフン）なしでご入力ください。</SmallText>
          </ContentsText>
          <FormInput
              v-model="form.tel"
              name="tel"
              type="text"
              placeholder="09012345678"
              maxlength="11"
              :error="'tel' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'tel' in errorMessage">{{errorMessage.tel}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>住所</HeadingLabel>
          <ContentsText>
            <SmallText>ご連絡がとれない場合、文書を送付させていただく場合がございます。</SmallText>
          </ContentsText>
          <ContentsText>郵便番号</ContentsText>
          <ContentsText>
            <SmallText>－（ハイフン）なしでご入力ください。</SmallText>
          </ContentsText>
          <FormInput
              v-model="form.postalCode"
              name="postalCode"
              type="text"
              placeholder="1234567"
              maxlength="7"
              :error="'postalCode' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'postalCode' in errorMessage">{{errorMessage.postalCode}}</ErrorMessage>
          <ContentsText>都道府県</ContentsText>
          <FormSelect
              v-model="form.address1"
              name="address1"
              :options="stateOption"
              :error="'address1' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'address1' in errorMessage">{{errorMessage.address1}}</ErrorMessage>
          <ContentsText>市区町村</ContentsText>
          <FormInput
              v-model="form.address2"
              name="address2"
              type="text"
              placeholder="港区"
              maxlength="100"
              :error="'address2' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'address2' in errorMessage">{{errorMessage.address2}}</ErrorMessage>
          <ContentsText>番地その他</ContentsText>
          <FormInput
              v-model="form.address3"
              name="address3"
              type="text"
              placeholder="1-2-3"
              maxlength="100"
              :error="'address3' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'address3' in errorMessage">{{errorMessage.address3}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>シリアル番号のわかる写真</HeadingLabel>
          <ContentsText>
            <SmallText>シリアル番号確認方法</SmallText>
            <SmallText variant="non-asterisk">「設定」→「一般」→「情報」にてシリアル番号の記載がございます。</SmallText>
            <SmallText variant="non-asterisk">シリアル番号が表示されている画面のスクリーンショットを添付ください。</SmallText>
            <SmallText variant="non-asterisk">もしくは、別のカメラで撮影したものを添付ください。</SmallText>
            <SmallText>カメラで撮影する場合は、画面全体が写るように撮影してください。</SmallText>
            <SmallText>ファイル形式はJPG、PNG、BMP、PDFのみとなります。</SmallText>
            <SmallText>画像のアップロードができない場合は、画像サイズを4MB以下に圧縮してください。</SmallText>
            <SmallText>圧縮の方法は、修理代金サポートセンターへお問い合わせください。</SmallText>
          </ContentsText>
          <FormFile
              name="deviceImageFilePath1"
              @change="setImageFile"
          ></FormFile>
          <ErrorMessage v-if="'deviceImageFilePath1' in errorMessage && errorMessage.deviceImageFilePath1 !== ''">{{errorMessage.deviceImageFilePath1}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>本体写真（画面側）</HeadingLabel>
          <ContentsText>
            <SmallText>iPhoneもしくはiPadの画面全体が写るように撮影してください。</SmallText>
            <SmallText>鏡の前でiPhoneのインカメラ(自撮り側)を利用し、撮影いただいても問題ございません。</SmallText>
            <SmallText>ファイル形式はJPG、PNG、BMP、PDFのみとなります。</SmallText>
            <SmallText>ファイルサイズは4MBまでとなります。</SmallText>
            <SmallText>圧縮の方法は、修理代金サポートセンターへお問い合わせください。</SmallText>
          </ContentsText>
          <FormFile
              name="deviceImageFilePath2"
              @change="setImageFile"
          ></FormFile>
          <ErrorMessage v-if="'deviceImageFilePath2' in errorMessage && errorMessage.deviceImageFilePath2 !== ''">{{errorMessage.deviceImageFilePath2}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>本体写真（カメラ側）</HeadingLabel>
          <ContentsText>
            <SmallText>iPhoneもしくはiPadの裏側（背面）全体が写るように撮影してください。</SmallText>
            <SmallText>鏡の前でiPhoneのカメラを利用し、撮影いただいても問題ございません。</SmallText>
            <SmallText>ファイル形式はJPG、PNG、BMP、PDFのみとなります。</SmallText>
            <SmallText>ファイルサイズは4MBまでとなります。</SmallText>
            <SmallText>圧縮の方法は、修理代金サポートセンターへお問い合わせください。</SmallText>
          </ContentsText>
          <FormFile
              name="deviceImageFilePath3"
              @change="setImageFile"
          ></FormFile>
          <ErrorMessage v-if="'deviceImageFilePath3' in errorMessage && errorMessage.deviceImageFilePath3 !== ''">{{errorMessage.deviceImageFilePath3}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <ContentsText>
            <InlineLink href="https://www.au-sonpo.co.jp/pc/lp_smapredousou/" target="_blank">ご契約のしおり</InlineLink>はこちらからご確認いただけます。
          </ContentsText>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="confirm">端末情報の事前登録確認画面へ</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <div v-show="isConfirm">
      <SectionHeading>端末情報事前登録の確認</SectionHeading>
      <ContentsContainer>
        <ContentsText>次の内容で申請します。</ContentsText>
        <ItemContainer>
          <HeadingLabel>ご契約者名/被保険者名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.insuredName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>現在のご利用端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.device}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>連絡先電話番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.tel}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>住所</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{address}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>シリアル番号画像</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.deviceImageFilePath1)}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>本体写真（画面側）</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.deviceImageFilePath2)}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>本体写真（カメラ側）</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.deviceImageFilePath3)}}</ContentsText>
        </ItemContainer>

        <ItemContainer>
          <ContentsText>
            <SmallText>申請中は新しいサポート申請を行うことはできません。<br>審査結果をお待ちいただき、申請一覧に不備が表示された場合は修正してください。</SmallText>
          </ContentsText>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="next" @click="submit">申請する</ButtonMain>
          <ButtonMain @click="edit">修正する</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <CommonFooter/>

    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
  import {
    postClaimInitApi,
    postClaimEntryApi,
    postClaimEntryCheckApi,
    postClaimEntryInsertApi,
    postClaimEntryUpdateApi,
  } from '@/utils/ApiHelper'
  import {PREFECTURES} from '@/utils/Constant'
  import {$_resultError, $_validationErrors, $_validationErrorMessage, $_errorMessages, $_isError} from '@/utils/Error'
  import {$_setUploadImageFile, $_fileName} from '@/utils/UploadImage'

  import CommonHeader from '@/components/common/CommonHeader'
  import CommonFooter from '@/components/common/CommonFooter'
  import CommonSupport from '@/components/common/CommonSupport'
  import CommonLoading from '@/components/common/CommonLoading'
  import MainContainer from '@/components/container/MainContainer'
  import ContentsContainer from '@/components/container/ContentsContainer'
  import ItemContainer from '@/components/container/ItemContainer'
  import SectionHeading from '@/components/heading/SectionHeading'
  import HeadingLabel from '@/components/heading/HeadingLabel'
  import ContentsText from '@/components/text/ContentsText'
  import SmallText from '@/components/text/SmallText'
  import InlineLink from '@/components/text/InlineLink'
  import FormInput from '@/components/form/FormInput'
  import FormSelect from '@/components/select/FormSelect'
  import FormFile from '@/components/form/FormFile'
  import ButtonContainer from '@/components/button/ButtonContainer'
  import ButtonMain from '@/components/button/ButtonMain'
  import ErrorBox from '@/components/error/ErrorBox'
  import ErrorMessage from '@/components/error/ErrorMessage'

  export default {
    name: "rep140",
    components: {
      CommonHeader,
      CommonFooter,
      CommonSupport,
      CommonLoading,
      MainContainer,
      ContentsContainer,
      ItemContainer,
      SectionHeading,
      HeadingLabel,
      ContentsText,
      SmallText,
      InlineLink,
      FormInput,
      FormSelect,
      FormFile,
      ButtonMain,
      ButtonContainer,
      ErrorBox,
      ErrorMessage
    },
    data() {
      return {
        status: 'edit',
        form: {
          registrationReservationId: '',
          insuredName:"",
          tel: "",
          postalCode: "",
          address1: "",
          address2: "",
          address3: "",
          deviceKind: 0,
          device: ""
        },
        file: {
          deviceImageFilePath1: '',
          deviceImageFilePath2: '',
          deviceImageFilePath3: '',
        },
        modelList: [],
        isLoading: false,
        resultError: []
      }
    },
    created() {
      window.addEventListener('beforeunload', this.beforeunload)
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.beforeunload)
    },
    computed: {
      modelTypeListOption() {
        let options = [{label: '選択してください', value: '', disabled: true}]
        for (let model of this.modelList) {
          options.push({label: model, value: model, disabled: false})
        }
        return options
      },
      mobileOption() {
        let options = [{label: '選択してください', value: 0, disabled: true}]
        options.push({label: "iPhone", value: 1, disabled: false})
        options.push({label: "iPad", value: 2, disabled: false})
        return options
      },
      stateOption() {
        let options = [{label: '選択してください', value: '', disabled: true}]

        for (const prefecture of PREFECTURES) {
          options.push({label: prefecture, value: prefecture, disabled: false})
        }
        return options
      },
      advanceEntryNumber () {
        return this.$store.getters.getAdvanceEntryNumber
      },
      isUpdate () {
        return this.$store.getters.getIsUpdate
      },
      isEdit() {
        return this.status === 'edit'
      },
      isConfirm() {
        return this.status === 'confirm'
      },
      address() {
        return `〒${this.form.postalCode} ${this.form.address1}${this.form.address2}${this.form.address3}`
      },
      errors() {
        return $_validationErrors(this.resultError)
      },
      isError() {
        return this.errors.length > 0
      },
      errorMessage() {
        return $_validationErrorMessage(this.resultError)
      },
      errorMessages () {
        return $_errorMessages(this.resultError)
      }
    },
    mounted() {
      this.isLoading = true
      postClaimInitApi().then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if ($_isError(this.resultError, this.$route)) {
          this.isLoading = false
        } else {
          this.form.registrationReservationId = result.registrationReservationId
          this.modelList = result.modelList
          this.claimEntry()
        }
      })
      .catch(() => {
        this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
      })
    },
    methods: {
      claimEntry() {
        if (!this.isUpdate) {
          this.isLoading = false
          return
        }
        postClaimEntryApi(this.form.registrationReservationId)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          $_isError(this.resultError, this.$route)
          for (let key in result) {
            if (Object.prototype.hasOwnProperty.call(this.form, key)) {
              this.form[key] = result[key]
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
      },
      selectDeviceKind(event) {
        if (event.target.value === '2') {
          this.form.device = 'iPad'
        } else {
          this.form.device = ''
        }
      },
      beforeunload(event) {
        event.returnValue = ''
      },
      setImageFile(fileList, el, form) {
        // UploadImageMixin
        $_setUploadImageFile(fileList, el, form, this)
      },
      confirm() {
        if (this.isLoading) return false

        this.isLoading = true
        this.resultError = []
        postClaimEntryCheckApi(this.form)
          .then(res => {
            const result = res.data.result
            this.resultError = $_resultError(result)
            if (!$_isError(this.resultError, this.$route)) {
              this.status = 'confirm'
            }
            this.scrollTop()
            this.isLoading = false
          })
          .catch(() => {
            this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
          })
      },
      submit() {
        if (this.isLoading) return false
        this.isLoading = true
        this.isUpdate ? this.updateEntry() : this.insertEntry()
      },
      insertEntry() {
        postClaimEntryInsertApi(this.form)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.advanceEntryNumber)
            this.$router.push({ name: 'rep170', query: {'p': this.$route.query.p} })
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
      },
      updateEntry() {
        postClaimEntryUpdateApi({...this.form, advanceEntryNumber: this.advanceEntryNumber})
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.advanceEntryNumber)
            this.$router.push({ name: 'rep170', query: {'p': this.$route.query.p} })
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
      },
      edit() {
        this.scrollTop()
        this.status = 'edit'
      },
      scrollTop() {
        this.$scrollTo('body', 1)
      },
      fileName(path) {
        return $_fileName(path)
      }
    }
  }
</script>
