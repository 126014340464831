<template>
  <div class="bank-info-box">
    <div class="inner">
      <div class="info">
        <p class="label">金融機関名</p>
        <p class="name">{{bankName || '未選択'}}</p>
      </div>
      <div class="info">
        <p class="label">支店名</p>
        <p class="name">{{officeName || '未選択'}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bank-info-box',
    props: {
      bankName: String,
      officeName: String
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .bank-info-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $gray-thin;
    border-top: 2px solid $orange-01;
    z-index: 1;

    .inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 940px;
      padding: 15px 20px;
      margin: 0 auto;

      .info {
        width: calc(50% - 10px);

        .label {
          font-size: 12px;
          line-height: 100%;
          color: $gray-text-02;
        }
        .name {
          height: 22px;
          padding-top: 7px;
          font-size: 15px;
          line-height: 100%;
          color: $black-01;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
</style>
