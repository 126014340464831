<template>
  <div class="button-toggle-body">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'button-toggle-body',
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/variables";
  .button-toggle-body {
    border-top: 1px solid $gray-line;
    padding: 25px 0 30px;

    :deep(.item-container) + .button-container {
      margin-top: 30px;
    }
  }
</style>
