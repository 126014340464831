<template>
  <li class="integrated-device-box-child"><slot></slot></li>
</template>

<script>
export default {
  name: 'integrated-device-box-child'
}
</script>

<style lang="scss" scoped>
.integrated-device-box-child {
  margin-top: 14px;
  &:first-child {
    margin-top: 0;
  }
}
</style>
