<template>
  <div class="card" :class="{'is-disabled':disabled}">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'card',
  props: {
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.card {
  padding: 12px 18px;
  border: 1px solid $gray-line;
  &.is-disabled {
    background-color: $gray-line;
  }

  + .card {
    margin-top: 12px;
  }
}
.heading-label + .card {
  margin-top: 20px;
}

.contents-text + .card {
  margin-top: 20px;
}
</style>
