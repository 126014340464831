import store from '@/store'
import router from '@/router'

export function $_resultError (result) {
  return result && result.errors ? result.errors : []
}

export function $_validationErrors (resultError) {
  return resultError ? resultError.filter(f => f.errorItem) : []
}

export function $_validationErrorMessage (resultError) {
  let errorMessage = {}
  const validationErrors = resultError ? resultError.filter(f => f.errorItem) : []
  for (const error of validationErrors) {
    if (error.errorItem) {
      errorMessage[error.errorItem] = error.errorMessage
    }
  }
  return errorMessage
}

export function $_errorMessages (resultError) {
  return resultError ? resultError.filter(error => !error.errorItem && error.errorMessage) : []
}

export function $_isError (resultError, route) {
  if (resultError.length === 0) {
    return false
  } else if (resultError.length === 1) {
    store.dispatch('setErrorMessage', resultError[0].errorMessage)
    let pageId = resultError[0].pageId ? resultError[0].pageId : 'rep300'
    let param = route.name === 'rep010' ? route.params.id : route.query.p
    router.push({name: pageId, query: {'p': param}})
    return true
  } else {
    return true
  }
}