<template>
  <MainContainer>
    <CommonHeader/>

    <div v-show="isEdit">
      <SectionHeading>修理補償申請</SectionHeading>
      <ContentsContainer>
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>
        <ContentsText>
          記入内容に不備があった場合は、申請一覧画面にてお知らせいたします。不備内容をご確認のうえ、修正をお願いします。<br>
          お客さま情報は、前日、前々日の情報をもとに表示しております。<br>
        </ContentsText>
      </ContentsContainer>

      <ContentsContainer>
        <ContentsHeading>お客さま情報</ContentsHeading>
        <ItemContainer>
          <HeadingLabel>ご契約者名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.contractorName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>電話番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.tel}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>現在のご利用端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.device}}</ContentsText>
        </ItemContainer>
      </ContentsContainer>

      <ContentsContainer>
        <ContentsHeading>お客さま入力項目</ContentsHeading>
        <ContentsText>修理完了時に受領された書類（修理完了報告書、請求書など）をご確認いただき、各項目を入力してください。<br>※Apple宅配修理サービスをご利用の場合は、Apple社から届くメールに添付される領収証・請求書をご確認ください。</ContentsText>
        <ItemContainer>
          <HeadingLabel>サポート対象端末</HeadingLabel>
          <ContentsText>
            <SmallText>申請する端末を選択してください。</SmallText>
          </ContentsText>
          <FormSelect
            v-model="form.deviceKind"
            name="deviceKind"
            :options="deviceKindOption"
            :error="'appDevice' in errorMessage && form.deviceKind !== 1"
            @input="selectDeviceKind"
          ></FormSelect>
          <FormSelect
            v-model="form.appDevice"
            name="appDevice"
            v-if="form.deviceKind === 1"
            :disabled="form.deviceKind !== 1"
            :options="appDeviceKindOption"
            :error="'appDevice' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'deviceKind' in errorMessage && !form.deviceKind">{{errorMessage.deviceKind}}</ErrorMessage>
          <ErrorMessage v-if="'appDevice' in errorMessage && form.deviceKind">{{errorMessage.appDevice}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>故障日</HeadingLabel>
          <ContentsText>
            <SmallText>故障日が不明の場合は、不明にチェックをいれてください。</SmallText>
          </ContentsText>
          <FormDate
              v-model="form.accidentDate"
              name="accidentDate"
              :disabled="isAccidentDateUnknown.length > 0"
              :error="'accidentDate' in errorMessage"
          ></FormDate>
          <FormCheckbox
              v-model="isAccidentDateUnknown"
              name="isAccidentDateUnknown"
              :options="accidentDateUnknownOption"
              @input="inputIsAccidentDateUnknown"
          ></FormCheckbox>
          <ErrorMessage v-if="'accidentDate' in errorMessage">{{errorMessage.accidentDate}}</ErrorMessage>
          <ErrorMessage v-if="'isAccidentDateUnknown' in errorMessage">{{errorMessage.isAccidentDateUnknown}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理完了日</HeadingLabel>
          <ContentsText>
            <SmallText>修理報告書に記載された完了日付を入力してください。<br>Apple宅配修理サービスをご利用の場合は、領収証・請求書内の「請求日」を入力してください。</SmallText>
            <SmallText>au iPhone故障紛失サポートセンターで修理交換サービスご利用の場合は、交換用携帯電話機お届けサービス受付票の「受付日」を入力してください。</SmallText>
          </ContentsText>
          <FormDate
            v-model="form.repairDate"
            name="repairDate"
            :error="'repairDate' in errorMessage"
          ></FormDate>
          <ErrorMessage v-if="'repairDate' in errorMessage">{{errorMessage.repairDate}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>故障内容</HeadingLabel>
          <FormCheckbox
              v-model="accidentCheck"
              name="accident"
              :options="accidentOption"
              @input="setFormAccident"
          ></FormCheckbox>
          <ErrorMessage v-if="'accidentDisplay' in errorMessage">{{errorMessage.accidentDisplay}}</ErrorMessage>
          <ErrorMessage v-if="'accidentNotDisplay' in errorMessage">{{errorMessage.accidentNotDisplay}}</ErrorMessage>
          <ErrorMessage v-if="'accidentInoperability' in errorMessage">{{errorMessage.accidentInoperability}}</ErrorMessage>
          <ErrorMessage v-if="'accidentOther' in errorMessage">{{errorMessage.accidentOther}}</ErrorMessage>
          <FormTextarea
            v-model="form.accidentOtherMemo"
            name="causeOtherMemo"
            :disabled="!accidentCheck.includes('accidentOther')"
            maxlength="200"
          ></FormTextarea>
          <ErrorMessage v-if="'accidentOtherMemo' in errorMessage">{{errorMessage.accidentOtherMemo}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>故障原因</HeadingLabel>
          <FormCheckbox
            v-model="causeCheck"
            name="cause"
            :options="causeOption"
            @input="setFormCause"
          ></FormCheckbox>
          <ErrorMessage v-if="'causeFall' in errorMessage">{{errorMessage.causeFall}}</ErrorMessage>
          <ErrorMessage v-if="'causeWater' in errorMessage">{{errorMessage.causeWater}}</ErrorMessage>
          <ErrorMessage v-if="'causeOther' in errorMessage">{{errorMessage.causeOther}}</ErrorMessage>
          <FormTextarea
            v-model="form.causeOtherMemo"
            name="causeOtherMemo"
            :disabled="!causeCheck.includes('causeOther')"
            maxlength="200"
          ></FormTextarea>
          <ErrorMessage v-if="'causeOtherMemo' in errorMessage">{{errorMessage.causeOtherMemo}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理報告書名義</HeadingLabel>
          <ContentsText>
            <SmallText class="is-red">修理報告書に記載されているお客様氏名を入力してください。</SmallText>
            <SmallText class="is-non-asterisk">なお、ご契約様のお名前と異なる際にはサポート対象外となる場合があります。</SmallText>
          </ContentsText>
          <FormInput
            v-model="form.reporterName"
            name="reporterName"
            type="text"
            :error="'reporterName' in errorMessage"
            maxlength="60"
            placeholder="英雄太郎"
          ></FormInput>
          <ErrorMessage v-if="'reporterName' in errorMessage">{{errorMessage.reporterName}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理店舗名</HeadingLabel>
          <ContentsText>
            <SmallText>Apple宅配修理サービスをご利用の場合は、「Apple Care（宅配サービス）」を選択してください。</SmallText>
            <SmallText>au iPhone故障紛失サポートセンターで修理交換サービスご利用の場合は、「au交換サービス」を選択してください。</SmallText>
            <SmallText>au Styleおよびauショップでの店頭修理サービスご利用の場合は、「auショップ」を選択してください。</SmallText>
          </ContentsText>
          <FormSelect
              v-model="form.storeName"
              name="storeName"
              :large="true"
              :options="storeOption"
              :error="'storeName' in errorMessage"
              maxlength="60"
          ></FormSelect>
          <ErrorMessage v-if="'storeName' in errorMessage">{{errorMessage.storeName}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理金額（税込）</HeadingLabel>
          <FormInput
              v-model="form.repairCost"
              name="repairCost"
              type="text"
              :error="'repairCost' in errorMessage"
              maxlength="7"
              placeholder="10000"
          ></FormInput>
          <ErrorMessage v-if="'repairCost' in errorMessage">{{errorMessage.repairCost}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理受付番号</HeadingLabel>
          <ContentsText><SmallText>修理証明書記載の管理番号を入力してください。</SmallText></ContentsText>
          <ContentsText><InlineLink @click="openModal">管理番号について</InlineLink></ContentsText>
          <FormInput
            v-model="form.acceptNumber"
            name="acceptNumber"
            type="text"
            :error="'acceptNumber' in errorMessage"
            maxlength="30"
            placeholder="0123456789"
          ></FormInput>
          <ErrorMessage v-if="'acceptNumber' in errorMessage">{{errorMessage.acceptNumber}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理証明書</HeadingLabel>
          <ContentsText>
            <SmallText>書類全体が写るように撮影してください。</SmallText>
            <SmallText>上限2枚までアップロード可能。</SmallText>
            <SmallText>ファイル形式はJPG、PNG、BMP、PDFのみとなります。</SmallText>
            <SmallText>ファイルサイズは4MBまでとなります。</SmallText>
          </ContentsText>
          <FormFile
            name="certificateFilePath1"
            @change="setImageFile"
          ></FormFile>
          <ErrorMessage v-if="'certificateFilePath1' in errorMessage && errorMessage.certificateFilePath1 !== ''">{{errorMessage.certificateFilePath1}}</ErrorMessage>
          <template v-if="form.certificateFilePath1 && form.certificateFilePath1 !== ''">
            <FormFile
                name="certificateFilePath2"
                @change="setImageFile"
            ></FormFile>
            <ErrorMessage v-if="'certificateFilePath2' in errorMessage && errorMessage.certificateFilePath2 !== ''">{{errorMessage.certificateFilePath2}}</ErrorMessage>
          </template>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>修理領収書</HeadingLabel>
          <ContentsText>
            <SmallText>任意入力</SmallText>
            <SmallText class="is-non-asterisk is-red">修理証明書に修理代金の記載がない場合は、契約者名と領収金額が記載された「修理領収書」のご提出も必要です。</SmallText>
            <SmallText>上限2枚までアップロード可能。</SmallText>
            <SmallText>ファイル形式はJPG、PNG、BMP、PDFのみとなります。</SmallText>
            <SmallText>ファイルサイズは4MBまでとなります。</SmallText>
          </ContentsText>
          <FormFile
            name="receiptFilePath1"
            @change="setImageFile"
          ></FormFile>
          <ErrorMessage v-if="'receiptFilePath1' in errorMessage && errorMessage.receiptFilePath1 !== ''">{{errorMessage.receiptFilePath1}}</ErrorMessage>
          <template v-if="form.receiptFilePath1 && form.receiptFilePath1 !== ''">
            <FormFile
                name="receiptFilePath2"
                @change="setImageFile"
            ></FormFile>
            <ErrorMessage v-if="'receiptFilePath2' in errorMessage && errorMessage.receiptFilePath2 !== ''">{{errorMessage.receiptFilePath2}}</ErrorMessage>
          </template>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="confirm">確認画面に進む</ButtonMain>
        </ButtonContainer>
        <CommonSupport />
      </ContentsContainer>
    </div>

    <div v-show="isConfirm">
      <SectionHeading>申請内容の確認</SectionHeading>
      <ContentsContainer>
        <ContentsText>次の内容で申請します。</ContentsText>
        <ItemContainer>
          <HeadingLabel>ご契約者名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.contractorName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>電話番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.tel}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>現在のご利用端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.device}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>サポート対象端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.appDevice}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>故障日</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">
            <template v-if="form.isAccidentDateUnknown">不明</template>
            <template v-else>{{form.accidentDate}}</template>
          </ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理完了日</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.repairDate}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>故障内容</HeadingLabel>
          <template v-for="(accident, index) in accidentOption">
            <ContentsText v-if="form[accident.value] === 1" :key="index" variant="bold" :breakword="true">{{accident.label}}</ContentsText>
          </template>
          <MessageBox v-if="form.accidentOther === 1 && form.accidentOtherMemo !== ''">{{form.accidentOtherMemo}}</MessageBox>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>故障原因</HeadingLabel>
          <template v-for="(cause, index) in causeOption">
            <ContentsText v-if="form[cause.value] === 1" :key="index" variant="bold" :breakword="true">{{cause.label}}</ContentsText>
          </template>
          <MessageBox v-if="form.causeOther === 1 && form.causeOtherMemo !== ''">{{form.causeOtherMemo}}</MessageBox>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理報告書名義</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.reporterName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理店舗名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.storeName}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理金額（税込）</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">￥{{form.repairCost}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理受付番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.acceptNumber}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理証明書</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.certificateFilePath1)}}</ContentsText>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.certificateFilePath2)}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>修理領収書</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.receiptFilePath1)}}</ContentsText>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.receiptFilePath2)}}</ContentsText>
        </ItemContainer>

        <ItemContainer>
          <ContentsText>
            <SmallText>申請中は新しいサポート申請を行うことはできません。<br>審査結果をお待ちいただき、申請一覧に不備が表示された場合は修正してください。</SmallText>
          </ContentsText>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain v-if="this.isUpdate" variant="primary" @click="updateRepair">申請する</ButtonMain>
          <ButtonMain v-else variant="next" @click="insertRepair">口座情報入力画面へ</ButtonMain>
          <ButtonMain @click="edit">修正する</ButtonMain>
        </ButtonContainer>
        <CommonSupport />
      </ContentsContainer>
    </div>

    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
    <AcceptNumberModal ref="acceptNumberModal"/>
  </MainContainer>
</template>

<script>
import {postClaimInitApi,
  postClaimSma2Api,
  postClaimSma2CheckApi,
  postClaimSma2InsertApi,
  postClaimSma2UpdateApi,
} from '../utils/ApiHelper'
import {$_resultError, $_validationErrors, $_validationErrorMessage, $_errorMessages, $_isError} from '@/utils/Error'
import {$_setUploadImageFile, $_fileName} from '@/utils/UploadImage'

import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import ItemContainer from '@/components/container/ItemContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsHeading from '@/components/heading/ContentsHeading'
import HeadingLabel from '@/components/heading/HeadingLabel'
import ContentsText from '@/components/text/ContentsText'
import SmallText from '@/components/text/SmallText'
import InlineLink from '@/components/text/InlineLink'
import FormInput from '@/components/form/FormInput'
import FormTextarea from '@/components/form/FormTextarea'
import FormSelect from '@/components/select/FormSelect'
import FormCheckbox from '@/components/select/FormCheckbox'
import FormDate from '@/components/form/FormDate'
import FormFile from '@/components/form/FormFile'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'
import ErrorBox from '@/components/error/ErrorBox'
import ErrorMessage from '@/components/error/ErrorMessage'
import MessageBox from '@/components/text/MessageBox'
import AcceptNumberModal from '@/components/modal/AcceptNumberModal'

export default {
  name: "rep120",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    ContentsContainer,
    ItemContainer,
    SectionHeading,
    ContentsHeading,
    HeadingLabel,
    ContentsText,
    SmallText,
    InlineLink,
    FormInput,
    FormTextarea,
    FormSelect,
    FormCheckbox,
    FormDate,
    FormFile,
    ButtonMain,
    ButtonContainer,
    ErrorBox,
    ErrorMessage,
    MessageBox,
    AcceptNumberModal
  },
  data() {
    return {
      status: 'edit',
      accidentCheck: [],
      causeCheck: [],
      isAccidentDateUnknown: [],
      form: {
        registrationReservationId: '',
        deviceKind: 0,
        appDevice: '',
        repairDate: '',
        accidentDate: '',
        isAccidentDateUnknown: false,
        accidentDisplay: 0,
        accidentNotDisplay: 0,
        accidentInoperability: 0,
        accidentOther: 0,
        accidentOtherMemo: '',
        causeFall: 0,
        causeWater: 0,
        causeOther: 0,
        causeOtherMemo: '',
        reporterName: '',
        storeName: '',
        repairCost: '',
        acceptNumber: ''
      },
      file: {
        certificateFilePath1: '',
        certificateFilePath2: '',
        receiptFilePath1: '',
        receiptFilePath2: ''
      },
      basicInfo: {
        contractorName: '',
        tel: '',
        device: ''
      },
      modelList: [],
      storeList: [],
      isLoading: false,
      resultError: []
    }
  },
  created () {
    window.addEventListener('beforeunload', this.beforeunload)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.beforeunload)
  },
  mounted() {
    this.isLoading = true
    postClaimInitApi().then(res => {
      const result = res.data.result
      this.resultError = $_resultError(result)
      if ($_isError(this.resultError, this.$route)) {
        this.isLoading = false
      } else {
        this.form.registrationReservationId = result.registrationReservationId
        if ('basicInfo' in result) {
          this.basicInfo = result.basicInfo
        }
        this.modelList = result.modelList
        this.storeList = result.storeList
        this.claimSma2()
      }
    })
    .catch(() => {
      this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
    })
  },
  computed: {
    deviceKindOption() {
      let options = [{label: '選択してください', value: 0, disabled: true}]
      options.push({label: "iPhone", value: 1, disabled: false})
      options.push({label: "iPad", value: 2, disabled: false})
      return options
    },
    appDeviceKindOption() {
      let options = [{label: '選択してください', value: '', disabled: true}]
      for (let model of this.modelList) {
        options.push({label: model, value: model, disabled: false})
      }
      return options
    },
    storeOption() {
      let options = [{label: '選択してください', value: '', disabled: true}]
      for (let store of this.storeList) {
        options.push({label: store, value: store, disabled: false})
      }
      return options
    },
    accidentDateUnknownOption() {
      return [{label: '不明', value: 'true'}]
    },
    accidentOption() {
      let options = [{label: '画面破損', value: 'accidentDisplay'}]
      options.push({label: '画面以外の破損（カメラ、スピーカーなど）', value: 'accidentNotDisplay'})
      options.push({label: '操作不能（電源入らず、ボタン操作不可など）', value: 'accidentInoperability'})
      options.push({label: 'その他', value: 'accidentOther'})
      return options
    },
    accidentValueList() {
      return this.accidentOption.map(accident => accident.value);
    },
    causeOption() {
      let options = [{label: '落下', value: 'causeFall'}]
      options.push({label: '水濡れ', value: 'causeWater'})
      options.push({label: 'その他', value: 'causeOther'})
      return options
    },
    appNumber () {
      return this.$store.getters.getAppNumber
    },
    isUpdate () {
      return this.$store.getters.getIsUpdate
    },
    causeValueList() {
      return this.causeOption.map(cause => cause.value);
    },
    isEdit() {
      return this.status === 'edit'
    },
    isConfirm() {
      return this.status === 'confirm'
    },
    errors() {
      return $_validationErrors(this.resultError)
    },
    isError() {
      return this.errors.length > 0
    },
    errorMessage() {
      return $_validationErrorMessage(this.resultError)
    },
    errorMessages () {
      return $_errorMessages(this.resultError)
    }
  },
  methods: {
    beforeunload (event) {
      event.returnValue = ''
    },
    claimSma2() {
      if (!this.isUpdate) {
        this.isLoading = false
        return
      }
      postClaimSma2Api(this.form.registrationReservationId)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        $_isError(this.resultError, this.$route)
        this.setFormData(result)
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
      })
    },
    setFormData(result) {
      for (let key in result) {
        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
          this.form[key] = result[key]
        }
        if (Object.prototype.hasOwnProperty.call(this.basicInfo, key)) {
          this.basicInfo[key] = result[key]
        }
        // deviceKindを調整
        if (key === 'appDevice' && this.form[key]) {
          this.form.deviceKind = this.form[key] === 'iPad' ? 2 : 1
        }
        if (key === 'isAccidentDateUnknown' && this.form[key] ) {
          this.isAccidentDateUnknown = this.form[key] === true ? ['true'] : []
        }
        if (this.accidentValueList.includes(key)) {
          if (this.form[key] === 1) {
            this.accidentCheck.push(key)
          }
        }
        if (this.causeValueList.includes(key)) {
          if (this.form[key] === 1) {
            this.causeCheck.push(key)
          }
        }
      }
    },
    selectDeviceKind(event) {
      if (event.target.value === '2') {
        this.form.appDevice = 'iPad'
      } else {
        this.form.appDevice = ''
      }
    },
    setFormAccident(event) {
      this.form[event.target.value] = event.target.checked ? 1 : 0
    },
    setFormCause(event) {
      this.form[event.target.value] = event.target.checked ? 1 : 0
    },
    setImageFile(fileList, el, form) {
      // UploadImageMixin
      $_setUploadImageFile(fileList, el, form, this)
    },
    inputIsAccidentDateUnknown(event) {
      this.form.isAccidentDateUnknown = event.target.checked
    },
    confirm () {
      if (this.isLoading) return false
      this.isLoading = true
      this.resultError = []
      postClaimSma2CheckApi(this.form)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if (!$_isError(this.resultError, this.$route)) {
          this.status = 'confirm'
        }
        this.scrollTop()
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
      })
      this.scrollTop()
    },
    insertRepair() {
      if (this.isLoading) return false
      this.isLoading = true
      postClaimSma2InsertApi(this.form)
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if (!$_isError(this.resultError, this.$route)) {
          this.$store.dispatch('setRegistrationReservationId', result.registrationReservationId)
          this.$router.push({ name: 'rep150', query: {'p': this.$route.query.p} })
        }
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
      })
    },
    updateRepair() {
      if (this.isLoading) return false
      this.isLoading = true
      postClaimSma2UpdateApi({...this.form, appNumber: this.appNumber})
      .then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if (!$_isError(this.resultError, this.$route)) {
          this.$store.dispatch('setReceiptNumber', result.appNumber)
          this.$router.push({ name: 'rep160', query: {'p': this.$route.query.p} })
        }
        this.isLoading = false
      })
      .catch(() => {
        this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
      })
    },
    edit() {
      this.scrollTop()
      this.status = 'edit'
    },
    scrollTop() {
      this.$scrollTo('body', 1)
    },
    fileName(path) {
      return $_fileName(path)
    },
    openModal(event) {
      event.preventDefault()
      this.$refs.acceptNumberModal.openModal()
    }
  }
}
</script>
