<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setMeta } from "@/utils/Meta";

export default {
  name: "App",
  setup() {
    setMeta({
      title: "修理代金サポートサービス",
    });
  }
}
</script>

<style lang="scss">
@import "@/assets/stylesheets/styles";
#app {
  height: 100%;
}
</style>